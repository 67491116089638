import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IExternalStaffingCandidateBidStatusEntity } from '../../interfaces/api/external-staffing-candidate-bid-status-entity';

@Injectable({
  providedIn: 'root',
})
export class ExternalStaffingCandidateBidStatusPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IExternalStaffingCandidateBidStatusEntity,
  IExternalStaffingCandidateBidStatusEntity[]
> {
  protected readonly endpoint = 'externalStaffingCandidateBidStatus';
}
