import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';

import { HttpApiPersistenceService, IQueryParams } from '@locumsnest/core/src';
import { Time } from '@locumsnest/core/src/lib/helpers';

import { LocumsNestEndpointConfig } from '../../core/constants';
import {
  IApplicationBaseEntity,
  IApplicationEntity,
  IBaseAdjacentApplicationEntity,
} from '../../interfaces/api/application-entity';
import { ICountEntity } from '../../interfaces/api/count-entity';

@Injectable({
  providedIn: 'root',
})
export class ApplicationPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IApplicationEntity
> {
  protected readonly endpoint = 'application';

  markCancellationAsSeen(id: string) {
    return this.create<{}, IApplicationEntity>(
      {},
      { pathParams: { id }, controllerResource: 'markCancellationAsSeen', skipSerializer: true },
    ).pipe(catchError((e) => of(e)));
  }

  approve(id: string) {
    return this.create<{}, IApplicationEntity>(
      {},
      { pathParams: { id }, controllerResource: 'approve', skipSerializer: true },
    );
  }

  decline(id: string, reason: number, otherReason: string = '') {
    const data: IApplicationBaseEntity = {
      declineReason: reason,
      declineReasonOther: otherReason,
    };

    return this.create<{}, IApplicationEntity>(data, {
      pathParams: { id },
      controllerResource: 'decline',
      skipSerializer: true,
    });
  }

  export(queryParams: IQueryParams) {
    return this.retrieve<ICountEntity>(queryParams, {
      controllerResource: 'exportApplications',
      skipSerializer: true,
    });
  }

  fetchAdjacent(profile: string, startTime: Date) {
    return this.retrieve<IBaseAdjacentApplicationEntity[]>(
      {
        profile,
        startTime: Time.formatISODate(startTime),
      },
      { controllerResource: 'adjacentApplications', skipSerializer: true },
    );
  }
}
