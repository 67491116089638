import { IWardEntityState } from './interfaces/index';
import { WardMessageTypes as MessageTypes, WardMessages } from './ward.messages';
import { adapter, initialState, paginationAdapter, loadingAdapter } from './ward.adapter';
import { combineReducers, Action } from '@ngrx/store';
import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';
import { IWardEntity } from '../../interfaces/api/ward-entity';
import { ILoadingState } from '@locumsnest/core/src/lib/adapters/loading-state-adapter/interfaces';

export * from './interfaces';

export const paginationReducer = paginationAdapter.createReducer();

export const loadingReducer = loadingAdapter.createReducer();
export function loadingStateReducer(s: ILoadingState, a: Action) {
  return loadingReducer(s, a);
}

export type IWardPaginatedEntityState = IWardEntityState & IPaginatedEntityState<IWardEntity>;

export function entityReducer(state = initialState, action: WardMessages): IWardEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}

export function paginatedEntityStateReducer(
  state: IWardPaginatedEntityState,
  action: WardMessages
) {
  let pagination;

  if (state) {
    pagination = state.pagination;
  }

  return {
    ...entityReducer(state, action),
    pagination: {
      ...paginationReducer(pagination, action),
    },
  };
}

export function reducer(state, action) {
  return combineReducers({
    entityLoadingState: loadingStateReducer,
    entityState: paginatedEntityStateReducer,
    pagination: paginationReducer,
  })(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();
