import { IJobListingBookingStats } from './../../interfaces/api/job-listing-entity';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IJobListingEntity } from '../../interfaces/api/job-listing-entity';
import {
  MessageableFactory,
  SignalableFactory,
  createPaginatedStateAdapter,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import {
  createSingletonResourceStateAdapter,
  ISingletonResourceAdapter,
} from '@locumsnest/core/src/lib/adapters/singleton-resource-adapter';
import { IStaffingCascadeEntityStats } from '../../interfaces/api/staffing-cascade-entity';

export const adapter: EntityAdapter<IJobListingEntity> = createEntityAdapter<IJobListingEntity>({});

export const messageableFactory = MessageableFactory.forFeature<'JobListing'>('JobListing');
export const signalableFactory = SignalableFactory.forFeature<'JobListing'>('JobListing');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'JobListing'>(messageableFactory);
export const bookingStatsAdapter: ISingletonResourceAdapter<number, IJobListingBookingStats> =
  createSingletonResourceStateAdapter<'JobListing', number, IJobListingBookingStats>(
    messageableFactory,
    'bookingStats'
  );

export const initialState = adapter.getInitialState({});
