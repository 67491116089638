import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IPreferredRosterEntity } from '../../../interfaces/api/preferred-roster-entity';

export enum PreferredRosterMessageTypes {
  SET_COLLECTION = '[PreferredRoster][Message] Set Collection Temp',
  RESET_COLLECTION = '[PreferredRoster][Message] Reset Collection Temp',
  ADD_ONE = '[PreferredRoster][Message] Add One Temp',
  UPDATE_ONE = '[PreferredRoster][Message] Update One Temp',
  UPSERT_ONE = '[PreferredRoster][Message] Upsert One Temp',
  DELETE_ONE = '[PreferredRoster][Message] Delete One Temp',
  ADD_MULTIPLE = '[PreferredRoster][Message] Add All Temp',
  DELETE_MULTIPLE = '[PreferredRoster][Message] Delete Many Temp',
  UPSERT_MULTIPLE = '[PreferredRoster][Message] Upsert Many Temp',
  UPDATE_MULTIPLE = '[PreferredRoster][Message] Update Many Temp',
}
export class SetCollectionMessage implements Action {
  readonly type = PreferredRosterMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IPreferredRosterEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = PreferredRosterMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IPreferredRosterEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = PreferredRosterMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IPreferredRosterEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = PreferredRosterMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IPreferredRosterEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = PreferredRosterMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IPreferredRosterEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = PreferredRosterMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IPreferredRosterEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = PreferredRosterMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IPreferredRosterEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = PreferredRosterMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = PreferredRosterMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: number[] }) {}
}
export class ResetCollectionMessage implements Action {
  readonly type = PreferredRosterMessageTypes.RESET_COLLECTION;

  constructor(public payload: Record<string, never>) {}
}

export type PreferredRosterMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | ResetCollectionMessage;
