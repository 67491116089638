import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { flatMap, get, isNil, sumBy } from 'lodash-es';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  merge,
  Observable,
  of,
  startWith,
  switchMap,
} from 'rxjs';

import {
  FilterNamespace,
  IFilterCategory,
  IFilterCategoryPayload,
  IFilterGroup,
  IFilterOption,
  IFilterSelectAllPayload,
} from '@locumsnest/components/src/lib/interfaces/filter';
import { IGlobalFilterService } from '@locumsnest/core/src/lib/services/interfaces';

import { PreferredProfessionSpecialtyService } from '../../preferred-profession-specialty/+state/preferred-profession-specialty.service';
import { PreferredProfessionService } from '../../preferred-profession/+state/preferred-profession.service';
import { PreferredRosterService } from '../../preferred-roster/+state/preferred-roster.service';
import { PreferredSiteService } from '../../preferred-site/+state/preferred-site.service';
import { PreferredSpecialtyCategoryService } from '../../preferred-specialty-category/+state/preferred-specialty-category.service';
import { ProfessionSpecialtyService } from '../../profession-specialty/+state/profession-specialty.service';
import { RosterService } from '../../roster/+state/roster.service';
import { SiteService } from '../../site/+state/site.service';
import { SpecialtyService } from '../../specialty/+state/specialty.service';
import { SubSpecialtyService } from '../../sub-specialty/+state/sub-specialty.service';
import { PRODUCT_CODES } from '../constants';
import { MicroAppService } from './../../../../../../libs/core/src/lib/micro-app/micro-app.service';
import { GradeService } from './../../grade/+state/grade.service';
import { PreferredWardService } from './../../preferred-ward/+state/preferred-ward.service';
import { ProfessionService } from './../../profession/+state/profession.service';
import { WardService } from './../../ward/+state/ward.service';
import {
  selectSelectAllInProgress,
  selectSelectedCategoryId,
  selectSelectedFilterGroup,
  selectShowDepartmentSection,
} from './filter-ui';
import { InitializeFilterContainerSignal } from './filter-ui/filter-ui.signals';
import { IGlobalFilters } from './interfaces/global-filters';

export abstract class CommonOfficerPreferenceFilterService
  implements IGlobalFilterService<IGlobalFilters | Record<string, never>>
{
  protected abstract preferredProfessionSpecialtyService: PreferredProfessionSpecialtyService;
  protected abstract preferredSiteService: PreferredSiteService;
  protected abstract preferredProfessionService: PreferredProfessionService;
  protected abstract preferredWardService: PreferredWardService;
  protected abstract professionService: ProfessionService;
  protected abstract rosterService: RosterService;
  protected abstract gradeService: GradeService;
  protected abstract microAppService: MicroAppService;
  protected abstract preferredRosterService: PreferredRosterService;

  getPreferredSites() {
    return this.preferredSiteService.getAllIdsAfterLoading();
  }

  getPreferredWards() {
    return this.preferredWardService.getAllIdsAfterLoading();
  }

  getPreferredRosters() {
    return this.preferredRosterService.getAllIdsAfterLoading();
  }

  getPreferredProfessionIds() {
    return combineLatest([
      this.preferredProfessionService.getAllIdsAfterLoading(),
      this.professionService.getAllIdsAfterLoading(),
    ]).pipe(
      map(([preferredProfessionIds, allProfessionIds]) =>
        preferredProfessionIds.length ? preferredProfessionIds : allProfessionIds,
      ),
    );
  }
  getPreferredProfessionSpecialties() {
    return this.getPreferredProfessionIds().pipe(
      switchMap((preferredProfessionIds) =>
        this.preferredProfessionSpecialtyService.getPreferredProfessionSpecialtyIdsForProfessions(
          preferredProfessionIds,
        ),
      ),
    );
  }

  getPreferredProfessionGrades() {
    return combineLatest([this.getPreferredProfessionIds(), this.gradeService.getAll()]).pipe(
      map(([preferredProfessionIds, grades]) =>
        grades.filter(({ profession }) => preferredProfessionIds.includes(profession)),
      ),
    );
  }

  getPreferredProfessionGradeIds() {
    return this.getPreferredProfessionGrades().pipe(
      map((grades) => grades.map(({ id }) => id)),
      distinctUntilChanged(),
    );
  }

  getFilteredGradeOptions() {
    return combineLatest([
      this.gradeService.getFilterGradeOptions(),
      this.getPreferredProfessionGradeIds(),
    ]).pipe(
      map(([gradeOptions, preferredProfessionGradeIds]) => {
        if (preferredProfessionGradeIds.length) {
          return gradeOptions.filter(({ id }) => preferredProfessionGradeIds.includes(id));
        }
        return gradeOptions;
      }),
    );
  }

  getGlobalFilters(): Observable<IGlobalFilters | Record<string, never>> {
    return this.microAppService.getMicroAppActive(PRODUCT_CODES.AGENCY).pipe(
      first(),
      switchMap((isAgency) => {
        if (isAgency) {
          return of<Record<string, never>>({});
        }
        return combineLatest([
          this.getPreferredWards(),
          this.getPreferredRosters(),
          this.getPreferredSites(),
          this.getPreferredProfessionSpecialties(),
          this.preferredWardService.getPrefersUnassignedWards(),
        ]).pipe(
          map(([ward, roster, site, professionSpecialty, prefersUnassignedWards]) => {
            let params: IGlobalFilters = {
              ward,
              roster,
              site,
              professionSpecialty,
            };

            if (prefersUnassignedWards) {
              params = {
                ...params,
                ward_roster_unassigned: true,
              };
            }

            return params;
          }),
        );
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(
    private store: Store,
    private preferredSpecialtyCategoryService: PreferredSpecialtyCategoryService,
    private preferredProfessionSpecialtyService: PreferredProfessionSpecialtyService,
    private specialtyService: SpecialtyService,
    private subSpecialtyService: SubSpecialtyService,
    private professionService: ProfessionService,
    private professionSpecialtyService: ProfessionSpecialtyService,
    private preferredSiteService: PreferredSiteService,
    private preferredProfessionService: PreferredProfessionService,
    private siteService: SiteService,
    private wardService: WardService,
    private rosterService: RosterService,
    private preferredWardService: PreferredWardService,
    private preferredRosterService: PreferredRosterService,
  ) {}

  initializeFilters() {
    return this.store.dispatch(new InitializeFilterContainerSignal({}));
  }

  loadFilters() {
    return merge(
      this.specialtyService.load(),
      this.subSpecialtyService.load(),
      this.professionService.load(),
      this.siteService.load(),
      this.professionSpecialtyService.load(),
      this.wardService.load(),
      this.rosterService.load(),
      this.preferredRosterService.load(),
      this.preferredSpecialtyCategoryService.load(),
      this.preferredProfessionService.load(),
      this.preferredSiteService.load(),
      this.preferredWardService.load(),
      this.preferredProfessionService.load(),
      this.preferredProfessionSpecialtyService.load(),
    );
  }

  hasSelectedProfessions() {
    return this.getProfessionFilterCategories().pipe(
      map((professionFilterCategories) => {
        if (!isNil(professionFilterCategories)) {
          for (const category of professionFilterCategories) {
            if (category.selected) {
              return true;
            }
          }
        }
        return false;
      }),
    );
  }

  hasSelectedWards() {
    return this.getProfessionFilterCategories().pipe(
      map((wardsFilterCategories) => {
        if (!isNil(wardsFilterCategories)) {
          for (const category of wardsFilterCategories) {
            if (category.selected) {
              return true;
            }
          }
        }
        return false;
      }),
    );
  }

  getWards() {
    return this.getFilterGroup().pipe(
      map((filterGroup) => (filterGroup.length > 0 ? filterGroup[0].options : [])),
    );
  }

  getProfessionFilterCategories() {
    return this.getFilterGroup().pipe(
      map((filterGroup) => (filterGroup.length > 0 ? filterGroup[0].filterCategories : [])),
    );
  }

  getFilterGroup() {
    return combineLatest([this.getFilterGroups(), this.getSelectedFilterGroup()]).pipe(
      map(([filterGroups, selectedFilterGroup]) =>
        filterGroups ? filterGroups.filter((x) => x.slug === selectedFilterGroup) : [],
      ),
    );
  }

  getFilterGroups() {
    return combineLatest([
      this.getProfessionFilterGroups(),
      this.getWardFilterGroups(),
      this.siteService.getFilters(),
    ]).pipe(
      map((filterGroups) =>
        filterGroups.filter(
          ({ options, filterCategories }) =>
            get(filterCategories, 'length') || get(options, 'length'),
        ),
      ),
    );
  }

  getFilterCount() {
    return combineLatest([
      this.getProfessionFilterGroups(),
      this.siteService.getFilters(),
      this.getWardFilterGroups(),
    ]).pipe(map(([profession, site, ward]) => profession.count + site.count + ward.count));
  }

  getFilterAlert() {
    return this.preferredWardService.getAlert();
  }

  getSelectedCategoryId() {
    return this.store.pipe(select(selectSelectedCategoryId));
  }

  getSelectAllInProgress() {
    return this.store.pipe(select(selectSelectAllInProgress));
  }
  getSelectedFilterGroup() {
    return this.store.pipe(select(selectSelectedFilterGroup));
  }
  getShowDepartmentSection() {
    return this.store.pipe(select(selectShowDepartmentSection));
  }
  getSelectedProfession() {
    return this.getSelectedCategoryId().pipe(switchMap((id) => this.professionService.getOne(id)));
  }
  getSelectedWard() {
    return this.getSelectedCategoryId().pipe(switchMap((id) => this.wardService.getOne(id)));
  }

  canSelectAll() {
    return combineLatest([
      this.getProfessionFilterGroups(),
      this.siteService.getFilters(),
      this.getWardFilterGroups(),
      this.getSelectedFilterGroup(),
      this.getShowDepartmentSection(),
    ]).pipe(
      map(([professions, sites, wards, selectedFilterGroup, showDepartmentSection]) => {
        if (selectedFilterGroup === 'Ward' && !showDepartmentSection) {
          return wards.filterCategories.every((category) => category.selected);
        }

        if (selectedFilterGroup === 'Ward' && showDepartmentSection) {
          const options = flatMap(
            wards.filterCategories.filter((category) => category.selected),
            (category) => category.filterOptions[0].options,
          );

          return options.every((option) => option.selected);
        }
        if (selectedFilterGroup === 'Site') {
          return sites.options.every((option) => option.selected);
        }
        if (selectedFilterGroup === 'Profession' && !showDepartmentSection) {
          return professions.filterCategories.every((category) => category.selected);
        }
        if (selectedFilterGroup === 'Profession' && showDepartmentSection) {
          const options = flatMap(
            professions.filterCategories.filter((category) => category.selected),
            (category) => category.filterOptions[0].options,
          );

          return options.every((option) => option.selected);
        }
        return false;
      }),
    );
  }

  getSelectedFilterGroups() {
    return this.getSelectedFilterGroup().pipe(
      switchMap((selectedFilterGroup) => {
        if (selectedFilterGroup === 'Profession') {
          return this.getProfessionFilterGroups();
        } else if (selectedFilterGroup === 'Ward') {
          return this.getWardFilterGroups();
        }
      }),
    );
  }

  getProfessionFilterGroups() {
    return combineLatest([
      this.professionService.getAllActive(),
      this.specialtyService.getAllFiltersAfterLoading().pipe(startWith([])),
      this.subSpecialtyService.getAllFiltersAfterLoading().pipe(startWith([])),
      this.preferredSpecialtyCategoryService.getAllTemp(),
      this.preferredProfessionSpecialtyService.getAllTemp(),
      this.professionSpecialtyService.getAllFiltersAfterLoading(),
      this.preferredProfessionService.getAllTempProfessionIds(),
    ]).pipe(
      map(
        ([
          professions,
          specialtyCategories,
          subSpecialties,
          preferredSpecialties,
          preferredProfessionSpecialties,
          professionSpecialties,
          preferredProfessionProfessionIds,
        ]): IFilterGroup => {
          const filterGroupsSections = professions.map((profession) => {
            const preferredProfession = preferredProfessionProfessionIds.find(
              (x) => x === profession.id,
            );

            const professionSpecialtyEntities =
              this.professionService.mergeProfessionWithSpecialtyEntities([
                profession,
                subSpecialties,
                professionSpecialties,
              ]);
            const usedCategoryIds = professionSpecialtyEntities.map(
              ({ specialty }) => specialty.category,
            );
            const preferredCategoryIds = professionSpecialtyEntities
              .filter(({ id }) =>
                preferredProfessionSpecialties.find(
                  ({ professionSpecialty }) => professionSpecialty === id,
                ),
              )
              .map(({ specialty }) => specialty.category);
            const preferredProfessionSpecialtyOptions = professionSpecialtyEntities.map(
              ({ id, specialty }, index) => {
                const preferredProfessionSpecialty = preferredProfessionSpecialties.find(
                  ({ professionSpecialty }) => professionSpecialty === id,
                );
                const visible =
                  preferredSpecialties.find(
                    (x) =>
                      x.specialtyCategory === specialty.category && x.profession === profession.id,
                  ) !== undefined || preferredCategoryIds.includes(specialty.category);
                const selected = !isNil(preferredProfessionSpecialty);
                return {
                  id: id ?? -1,
                  name: specialty.title,
                  order: index,
                  category: specialty.category,
                  visible,
                  selected,
                  parentId: profession.id,
                };
              },
            );

            const specialtyOptions = specialtyCategories
              .filter(({ id }) => usedCategoryIds.includes(id))
              .map((specialtyCategory, index) => ({
                id: specialtyCategory.id,
                name: specialtyCategory.title,
                order: index,
                visible: true,
                selected:
                  preferredSpecialties.find(
                    (x) =>
                      x.specialtyCategory === specialtyCategory.id &&
                      x.profession === profession.id,
                  ) !== undefined,
                parentId: profession.id,
              }));
            const filterOptions: IFilterOption[] = [
              {
                displayName: 'Department access',
                count: specialtyOptions.filter((x) => x.selected).length,
                options: specialtyOptions.sort(function (a, b) {
                  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                }),
              },
              {
                displayName: 'Sub-Department access',
                count: preferredProfessionSpecialtyOptions.filter((x) => x.selected).length,
                options: preferredProfessionSpecialtyOptions.sort(function (a, b) {
                  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                }),
              },
            ];

            const filterCategory: IFilterCategory = {
              id: profession.id,
              displayName: profession.title,
              selected: !isNil(preferredProfession),
              count: preferredProfessionSpecialtyOptions.filter((x) => x.selected).length,
              filterOptions,
            };

            return filterCategory;
          });

          const filterGroup: IFilterGroup = {
            displayName: 'Professions',
            slug: 'Profession',
            count: sumBy(filterGroupsSections, 'count'),
            filterCategories: filterGroupsSections,
            hasPreferredProfession: preferredProfessionProfessionIds.length > 0,
            namespace: FilterNamespace.default,
          };

          return filterGroup;
        },
      ),
    );
  }

  getWardFilterGroups() {
    return combineLatest([
      this.wardService.getAllActive(),
      this.rosterService.getAllFiltersAfterLoading().pipe(startWith([])),
      this.preferredRosterService.getAllTemp(),
      this.preferredWardService.getAllTempWardsIds(),
      this.preferredWardService.getPrefersUnassignedWardsTemp(),
    ]).pipe(
      map(
        ([
          wards,
          rosters,
          preferredRosters,
          preferredWardsIds,
          prefersUnassignedWards,
        ]): IFilterGroup => {
          const filterGroupsSections = wards
            .map((ward) => {
              const preferredWard = preferredWardsIds.find((x) => x === ward.id);

              const rosterOptions = rosters
                .filter((roster) => roster.ward === ward.id)
                .map((roster, index) => ({
                  id: roster.id,
                  name: roster.name,
                  order: index,
                  visible: true,
                  selected: preferredRosters.find((x) => x.roster === roster.id) !== undefined,
                  parentId: ward.id,
                }))
                .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

              const filterOptions: IFilterOption[] = [
                {
                  displayName: 'Roster',
                  count: rosterOptions.filter((x) => x.selected).length,
                  options: rosterOptions.sort(function (a, b) {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                  }),
                },
              ];

              const filterCategory: IFilterCategory = {
                id: ward.id,
                displayName: ward.name,
                selected: !isNil(preferredWard),
                count: rosterOptions.length
                  ? rosterOptions.filter((x) => x.selected).length
                  : !isNil(preferredWard)
                  ? 1
                  : 0,
                filterOptions,
              };
              return filterCategory;
            })
            .sort((a, b) =>
              a.displayName > b.displayName ? 1 : a.displayName < b.displayName ? -1 : 0,
            );

          const unassignedRosters = rosters
            .filter((roster) => roster.ward === null)
            .map((roster, index) => ({
              id: roster.id,
              name: roster.name,
              order: index,
              visible: true,
              selected: preferredRosters.find((x) => x.roster === roster.id) !== undefined,
              parentId: null,
            }))
            .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

          if (unassignedRosters.length > 0) {
            filterGroupsSections.unshift({
              id: -1,
              displayName: 'Rosters without assigned wards',
              selected:
                unassignedRosters.filter((x) => x.selected).length === unassignedRosters.length,
              count: unassignedRosters.filter((x) => x.selected).length,
              filterOptions: [{ count: 0, displayName: 'Roster', options: unassignedRosters }],
            });
          }

          filterGroupsSections.unshift({
            id: -2,
            displayName: 'Shifts without assigned wards/rosters',
            selected: prefersUnassignedWards,
            count: prefersUnassignedWards ? 1 : 0,
            filterOptions: [{ count: 0, displayName: 'Roster', options: [] }],
          });

          const filterGroup: IFilterGroup = {
            displayName: 'Wards & Rosters',
            slug: 'Ward',
            count: sumBy(filterGroupsSections, 'count'),
            filterCategories: filterGroupsSections,
            hasPreferredProfession: preferredWardsIds.length > 0,
            notes: 'Note: Not all professions make use of e-rosters.',
            namespace: FilterNamespace.default,
          };

          return filterGroup;
        },
      ),
    );
  }

  dispatchToggleSignal(payload: IFilterCategoryPayload) {
    if (payload.filterGroupName === 'Profession') {
      return this.preferredProfessionService.isFilterEnabled$.pipe(
        first(),
        filter((isEnabled) => isEnabled === true),
        switchMap(() => {
          this.preferredProfessionService.dispatchToggleSignals(payload);
          return of();
        }),
      );
    } else if (payload.filterGroupName === 'Ward') {
      return this.preferredWardService.isFilterEnabled$.pipe(
        first(),
        filter((isEnabled) => isEnabled === true),
        switchMap(() => {
          this.preferredWardService.dispatchToggleSignals(payload);
          return of();
        }),
      );
    }
  }

  dispatchToggleAllProfessionsSignal(payload: IFilterSelectAllPayload) {
    return this.preferredProfessionService.isFilterEnabled$.pipe(
      first(),
      filter((isEnabled) => isEnabled === true),
      switchMap(() => {
        this.preferredProfessionService.dispatchToggleAllSignal(payload);
        return of();
      }),
    );
  }

  dispatchToggleAllWardsSignal(payload: IFilterSelectAllPayload) {
    return this.preferredWardService.isFilterEnabled$.pipe(
      first(),
      filter((isEnabled) => isEnabled === true),
      switchMap(() => {
        this.preferredWardService.dispatchToggleAllSignal(payload);
        return of();
      }),
    );
  }
}
