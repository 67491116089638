import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs';

import { AttributeStateService } from '@locumsnest/core';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { IExternalStaffingCandidateBidStatusEntity } from '../../interfaces/api/external-staffing-candidate-bid-status-entity';
import { SetCollectionMessage } from './external-staffing-candidate-bid-status.messages';
import { ExternalStaffingCandidateBidStatusPersistenceService } from './external-staffing-candidate-bid-status.persistence.service';
import {
  selectAllExternalStaffingCandidateBidStatuses,
  selectExternalStaffingCandidateBidStatus,
  selectExternalStaffingCandidateBidStatusCodeById,
  selectExternalStaffingCandidateBidStatusCompletedIds,
  selectExternalStaffingCandidateBidStatusDisplay,
  selectFilterExternalStaffingCandidateBidStatusOptions,
} from './external-staffing-candidate-bid-status.selectors';

@Injectable({
  providedIn: 'root',
})
export class ExternalStaffingCandidateBidStatusService extends AttributeStateService<IExternalStaffingCandidateBidStatusEntity> {
  constructor(
    private store: Store,
    protected persistenceService: ExternalStaffingCandidateBidStatusPersistenceService,
  ) {
    super();
  }

  getAll() {
    return this.store.pipe(select(selectAllExternalStaffingCandidateBidStatuses), filterNonEmpty);
  }

  getAllAfterLoading() {
    return this.getAll().pipe(filter((x) => x && x.length > 0));
  }

  getOne(id: number) {
    return this.store.pipe(select(selectExternalStaffingCandidateBidStatus(id)));
  }

  getOneDisplay(id: number) {
    return this.store.pipe(select(selectExternalStaffingCandidateBidStatusDisplay(id)));
  }

  getExternalStaffingCandidateBidStatusCodeById(id: number) {
    return this.store.pipe(select(selectExternalStaffingCandidateBidStatusCodeById(id)));
  }

  getFilterExternalStaffingCandidateBidStatusOptions() {
    return this.store.pipe(select(selectFilterExternalStaffingCandidateBidStatusOptions));
  }

  getExternalStaffingCandidateBidStatusCompletedIds() {
    return this.store.pipe(select(selectExternalStaffingCandidateBidStatusCompletedIds));
  }

  getExternalStaffingCandidateBidStatusByCode(code: string) {
    return this.getAll().pipe(map((statuses) => statuses.find((status) => status.code === code)));
  }

  getSubmittedStatusCode() {
    return this.getExternalStaffingCandidateBidStatusByCode('SUBMITTED');
  }

  getAlertStatusValues() {
    return this.getAllAfterLoading().pipe(
      map((statuses) => statuses.filter((x) => x.code === 'CANCELED').map((x) => x.val)),
    );
  }

  getAlertStatusValuesAsString() {
    return this.getAlertStatusValues().pipe(map((statuses) => statuses.map((x) => x.toString())));
  }

  fetch() {
    return this.persistenceService
      .retrieve()
      .pipe(
        map(
          (externalStaffingCandidateBidStatuses) =>
            new SetCollectionMessage({ externalStaffingCandidateBidStatuses }),
        ),
      );
  }
}
