import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { difference } from 'lodash-es';
import { combineLatest, merge, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import { IFilterGroup, IOption } from '@locumsnest/components/src/lib/interfaces/filter';
import { FilterStateService } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';

import { PRODUCT_CODES } from '../../core/constants';
import {
  IPreferredRosterEntity,
  IPreferredRosterPostEntity,
} from '../../interfaces/api/preferred-roster-entity';
import { IRosterEntity } from '../../interfaces/api/roster-entity';
import { FilterNamespace } from './../../../../../../libs/components/src/lib/interfaces/filter';
import { RosterService } from './../../roster/+state/roster.service';
import { loadingAdapter } from './preferred-roster.adapter';
import {
  PreferredRosterPaginationMessages,
  ResetPreferredRosterPaginationMessage,
  UpsertMultipleMessage,
  UpsertPreferredRosterPageMessage,
} from './preferred-roster.messages';
import { PreferredRosterPersistenceService } from './preferred-roster.persistence.service';
import {
  preferredRosterPaginationSelectors,
  selectAllPreferredRosterIds,
  selectAllPreferredRostersTemp,
  selectPreferredRosterEntityState,
} from './preferred-roster.selectors';
import * as tempMessages from './temp/preferred-roster.messages';

@Injectable({
  providedIn: 'root',
})
export class PreferredRosterService extends FilterStateService<
  IPreferredRosterEntity,
  UpsertPreferredRosterPageMessage,
  ResetPreferredRosterPaginationMessage,
  UpsertMultipleMessage
> {
  protected readonly scope = [
    PRODUCT_CODES.MATCH,
    PRODUCT_CODES.LINK,
    PRODUCT_CODES.INTELLIGENCE,
    PRODUCT_CODES.COMMUNITY,
  ];
  constructor(
    protected store: Store,
    protected persistenceService: PreferredRosterPersistenceService,
    protected rosterService: RosterService,
    protected microAppService: MicroAppService
  ) {
    super();
  }

  get paginationMessages() {
    return PreferredRosterPaginationMessages;
  }

  get paginationSelectors() {
    return preferredRosterPaginationSelectors;
  }

  get entityStateSelector() {
    return selectPreferredRosterEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }
  getAllTemp() {
    return this.isFilterEnabled$.pipe(
      switchMap((isFilterEnabled) => {
        if (isFilterEnabled) {
          return this.store.pipe(select(selectAllPreferredRostersTemp));
        }

        return of<IPreferredRosterEntity[]>([]);
      })
    );
  }

  getAllTempCount() {
    return this.getAllTemp().pipe(map((preferences) => preferences.length));
  }
  getTempExists() {
    return this.getAllTempCount().pipe(
      map((count) => !!count),
      distinctUntilChanged()
    );
  }

  getAllTempRosterIds() {
    return this.getAllTemp().pipe(map((entities) => entities.map(({ roster }) => roster)));
  }

  getInexistentByWard(wardId: number) {
    return combineLatest([this.getAllTemp(), this.rosterService.getActiveIdsByWardId(wardId)]).pipe(
      map(([preferredRosters, activeIds]) =>
        difference(
          activeIds,
          preferredRosters.map((roster) => roster.roster)
        )
      )
    );
  }

  getTempRosters() {
    return combineLatest([this.getAllTemp(), this.rosterService.getAllActive()]).pipe(
      map(([preferredRosters, rosters]) =>
        rosters.filter((roster) =>
          preferredRosters.map((preferredRoster) => preferredRoster.roster).includes(roster.id)
        )
      )
    );
  }

  getTempPreferredUnassignedRosters() {
    return combineLatest([this.getAllTemp(), this.rosterService.getAllActive()]).pipe(
      map(([preferredRosters, rosters]) => {
        const unassignedRosterIds = rosters
          .filter((roster) => roster.ward === null)
          .map(({ id }) => id);

        return preferredRosters.filter((preferredRoster) =>
          unassignedRosterIds.includes(preferredRoster.roster)
        );
      })
    );
  }

  bulkCreate(rosters: IPreferredRosterPostEntity[]) {
    return this.persistenceService.bulkCreateFilter(rosters).pipe(
      map(
        (response: IPreferredRosterEntity[]) =>
          new tempMessages.UpsertMultipleMessage({
            entities: response,
          })
      )
    );
  }

  bulkDelete(preferredRosterIds: number[]) {
    return this.persistenceService.clear({ id: preferredRosterIds }).pipe(
      map(
        () =>
          new tempMessages.DeleteMultipleMessage({
            ids: preferredRosterIds,
          })
      )
    );
  }

  clear() {
    return this.persistenceService
      .clear({})
      .pipe(map(() => new tempMessages.ResetCollectionMessage({})));
  }

  getAllIds() {
    return this.store.pipe(select(selectAllPreferredRosterIds));
  }

  getAllIdsAfterLoading() {
    return this.isLoaded('default').pipe(
      filter((isLoaded) => isLoaded === true),
      mergeMap(() => this.getAllIds())
    );
  }

  getAllTempByWard(wardId: number) {
    return combineLatest([this.rosterService.getActiveIdsByWardId(wardId), this.getAllTemp()]).pipe(
      map(([available, preferred]) => preferred.filter(({ roster }) => available.includes(roster)))
    );
  }

  getAllTempIdsByWard(wardId: number) {
    return combineLatest([this.rosterService.getActiveIdsByWardId(wardId), this.getAllTemp()]).pipe(
      map(([available, preferred]) =>
        preferred.filter(({ roster }) => available.includes(roster)).map(({ id }) => id)
      )
    );
  }

  getFilters() {
    return combineLatest([this.rosterService.getAll(), this.getAllTempRosterIds()]).pipe(
      map(([rosters, preferredRosterIds]: [IRosterEntity[], number[]]): IFilterGroup => {
        let order = 0;
        const options = rosters.map((roster) => {
          const option: IOption = {
            id: roster.id,
            name: roster.name,
            order: order++,
            visible: true,
            selected: preferredRosterIds.includes(roster.id),
            parentId: null,
          };

          return option;
        });

        const filterGroup: IFilterGroup = {
          displayName: 'Roster',
          slug: 'Roster',
          count: options.filter((x) => x.selected).length,
          options: options.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)),
          namespace: FilterNamespace.default,
          optional: true,
        };

        return filterGroup;
      })
    );
  }

  fetch() {
    return merge(
      this.loadAllPages('default', null),
      this.isLoaded('default').pipe(
        filter((isLoaded) => isLoaded === true),
        first(),
        mergeMap(() =>
          this.getAll().pipe(map((entities) => new tempMessages.SetCollectionMessage({ entities })))
        )
      )
    );
  }
}
