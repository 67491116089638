import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IRosterEntityState, IRosterFeatureState, IRosterSharedState } from './interfaces';
import { loadingAdapter, paginationAdapter } from './roster.adapter';
import { selectAll } from './roster.reducer';

export const getEntityState = (state: { entityState: IRosterEntityState }) => state.entityState;

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectRosterState =
  createFeatureSelector<IRosterFeatureState & IRosterSharedState>('rosters');

export const selectRosterEntityState = createSelector(selectRosterState, getEntityState);

export const selectAllRosters = createSelector(selectRosterEntityState, selectAll);

export const loadingStateSelectors = loadingAdapter.getSelectors(selectRosterState);

export const rosterPaginationSelectors = paginationAdapter.paginationSelectors(
  selectRosterState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);
