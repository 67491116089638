import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IRosterEntity } from '../../interfaces/api/roster-entity';

@Injectable({
  providedIn: 'root',
})
export class RosterPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IRosterEntity
> {
  protected readonly endpoint = 'roster';
}
