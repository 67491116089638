import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IPreferredRosterEntity } from '../../interfaces/api/preferred-roster-entity';
import { IPreferredRosterEntityState } from './interfaces';
import { paginationAdapter } from './preferred-roster.adapter';

export const PreferredRosterPaginationMessages = paginationAdapter.getMessages();

export class UpsertPreferredRosterPageMessage extends PreferredRosterPaginationMessages.UpsertPageMessage {}
export class UpsertPreferredRosterMultiplePagesMessage extends PreferredRosterPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetPreferredRosterPaginationMessage extends PreferredRosterPaginationMessages.ResetPaginationMessage {}

export enum PreferredRosterMessageTypes {
  SET_COLLECTION = '[PreferredRoster][Message] Set Collection',
  ADD_ONE = '[PreferredRoster][Message] Add One',
  UPDATE_ONE = '[PreferredRoster][Message] Update One',
  UPSERT_ONE = '[PreferredRoster][Message] Upsert One',
  DELETE_ONE = '[PreferredRoster][Message] Delete One',
  ADD_MULTIPLE = '[PreferredRoster][Message] Add All',
  DELETE_MULTIPLE = '[PreferredRoster][Message] Delete Many',
  UPSERT_MULTIPLE = '[PreferredRoster][Message] Upsert Many',
  UPDATE_MULTIPLE = '[PreferredRoster][Message] Update Many',
  UPDATE_ENTITY_STATE = '[PreferredRoster][Message] Update Entity State',
}
export class SetCollectionMessage implements Action {
  readonly type = PreferredRosterMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IPreferredRosterEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = PreferredRosterMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IPreferredRosterEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = PreferredRosterMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IPreferredRosterEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = PreferredRosterMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IPreferredRosterEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = PreferredRosterMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IPreferredRosterEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = PreferredRosterMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IPreferredRosterEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = PreferredRosterMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IPreferredRosterEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = PreferredRosterMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = PreferredRosterMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export class UpdateEntityStateMessage implements Action {
  readonly type = PreferredRosterMessageTypes.UPDATE_ENTITY_STATE;

  constructor(
    public payload: {
      preferredRosterTempState: IPreferredRosterEntityState;
    }
  ) {}
}

export type PreferredRosterMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpdateEntityStateMessage;
