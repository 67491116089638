import { signalableFactory as actionableFactory } from './filter-ui.adapter';

export class InitializeFilterContainerSignal extends actionableFactory.create<
  'Initialize Filter Container',
  Record<string, never>
>('Initialize Filter Container') {}

export class ShowHideDepartmentSectionSignal extends actionableFactory.create<
  'Show Hide Department Section Signal',
  Record<string, never>
>('Show Hide Department Section Signal') {}

export class UpdateSelectedFilterGroupSignal extends actionableFactory.create<
  'Update Selected Filter Group Signal',
  { selectedFilterGroup: string }
>('Update Selected Filter Group Signal') {}

export class UpdateSelectedCategorySignal extends actionableFactory.create<
  'Update Selected Category Signal',
  { selectedCategoryId: number }
>('Update Selected Category Signal') {}
