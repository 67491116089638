import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IWardEntity } from '../../interfaces/api/ward-entity';
import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';

export const adapter: EntityAdapter<IWardEntity> = createEntityAdapter<IWardEntity>({});
export const messageableFactory = MessageableFactory.forFeature<'Ward'>('Ward');
export const signalableFactory = SignalableFactory.forFeature<'Ward'>('Ward');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'Ward'>(messageableFactory);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedWardId: null,
});
