import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { FilterStateService } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';

import { PRODUCT_CODES } from '../../core/constants';
import { IRosterEntity } from '../../interfaces/api/roster-entity';
import {
  ResetRosterPaginationMessage,
  RosterPaginationMessages,
  UpsertMultipleMessage,
  UpsertMultiplePagesMessage,
  UpsertRosterPageMessage,
} from './roster.messages';
import { RosterPersistenceService } from './roster.persistence.service';
import { rosterPaginationSelectors, selectRosterEntityState } from './roster.selectors';

@Injectable({
  providedIn: 'root',
})
export class RosterService extends FilterStateService<
  IRosterEntity,
  UpsertRosterPageMessage,
  ResetRosterPaginationMessage,
  UpsertMultipleMessage
> {
  protected readonly scope = [
    PRODUCT_CODES.MATCH,
    PRODUCT_CODES.LINK,
    PRODUCT_CODES.INTELLIGENCE,
    PRODUCT_CODES.COMMUNITY,
    PRODUCT_CODES.PASSPORT_PLUS,
  ];

  constructor(
    protected store: Store,
    protected persistenceService: RosterPersistenceService,
    protected microAppService: MicroAppService
  ) {
    super();
  }

  get paginationMessages() {
    return RosterPaginationMessages;
  }

  get upsertMessageClass() {
    return UpsertRosterPageMessage;
  }
  get clearMessageClass() {
    return ResetRosterPaginationMessage;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get upsertMultiplePagesMessage() {
    return UpsertMultiplePagesMessage;
  }

  get paginationSelectors() {
    return rosterPaginationSelectors;
  }

  get entityStateSelector() {
    return selectRosterEntityState;
  }

  fetch() {
    return this.loadAllPages('default', null);
  }

  getAllActive() {
    return this.getAll().pipe(map((rosters) => rosters.filter(({ active }) => !!active)));
  }

  getActiveByWardId(wardId: number) {
    return this.getAllActive().pipe(map((wards) => wards.filter((x) => x.ward === wardId)));
  }

  getActiveIdsByWardId(wardId: number) {
    return this.getActiveByWardId(wardId).pipe(map((entities) => entities.map(({ id }) => id)));
  }
}
