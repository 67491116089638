import { IPreferredRosterEntityState } from '../interfaces/index';
import {
  PreferredRosterMessageTypes as MessageTypes,
  PreferredRosterMessages,
} from './preferred-roster.messages';
import { adapter, initialState } from '../preferred-roster.adapter';

export * from '../interfaces';
const DEFAULT_STATE = { ...initialState };
export function tempReducer(
  state = DEFAULT_STATE,
  action: PreferredRosterMessages
): IPreferredRosterEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.RESET_COLLECTION: {
      return { ...initialState };
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    default: {
      return state;
    }
  }
}
