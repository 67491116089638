import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';

import { IGradeEntity } from '../../interfaces/api/grade-entity';
import { selectAll } from './grade.reducer';
import { IGradeEntityState, IGradeFeatureState, IGradeSharedState } from './interfaces';

export const getEntityState = (state: { entityState: IGradeEntityState }) => state.entityState;

export const getGradeOptions = (grades: IGradeEntity[]) =>
  grades.map((grade) => ({ id: grade.id, name: grade.title }));

export const getGrade = (gradeId: number) => (grades: IGradeEntity[]) =>
  grades.find((grade) => grade.id === gradeId);
export const getGradeProfession = (grade: IGradeEntity) => grade.profession;
export const getFilterGradeOptions = (grades: IGradeEntity[]): ISelectOption[] =>
  grades.map((grade) => ({ id: grade.id, name: grade.title, profession: grade.profession }));

export const getPrimaryCareGrades = (allGrades: IGradeEntity[]) =>
  allGrades.filter((grade) => grade.slug === 'GP' || grade.slug.startsWith('primary-care'));

export const getNonPrimaryCareGrades = (allGrades: IGradeEntity[]) =>
  allGrades.filter((grade) => !grade.slug.startsWith('primary-care'));

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectGradeState = createFeatureSelector<IGradeFeatureState & IGradeSharedState>(
  'grades',
);
export const selectGradeEntityState = createSelector(selectGradeState, getEntityState);

export const selectAllGrades = createSelector(selectGradeEntityState, selectAll);

export const selectGradeOptions = createSelector(selectAllGrades, getGradeOptions);

export const selectGrade = (gradeId) => createSelector(selectAllGrades, getGrade(gradeId));
export const selectGradeProfession = (gradeId) =>
  createSelector(selectGrade(gradeId), getGradeProfession);
export const selectPrimaryCareGrades = createSelector(selectAllGrades, getPrimaryCareGrades);
export const selectNonPrimaryCareGrades = createSelector(selectAllGrades, getNonPrimaryCareGrades);

export const selectFilterGradeOptions = createSelector(selectAllGrades, getFilterGradeOptions);
