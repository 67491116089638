import { MessageableFactory } from '../../ngrx';
import { createSingletonResourceStateFactory } from './singleton-resource-reducer';
import { createSelectorFactory } from './singleton-resource-selectors';
export * from './interfaces';
export function createSingletonResourceStateAdapter<F extends string, I extends string | number, T>(
  messageableFactory: MessageableFactory<F>,
  id: string
) {
  return {
    ...createSingletonResourceStateFactory<F, I, T>(messageableFactory, id),
    ...createSelectorFactory(id),
  };
}
