import { Injectable } from '@angular/core';

import { HttpApiPersistenceService, IPaginatedResponse, IQueryParams } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { ICountEntity } from '../../interfaces/api/count-entity';
import { IHospitalStaffBankUnavailabilityEntity } from '../../interfaces/api/hospital-staff-bank-unavailability-entity';

@Injectable({
  providedIn: 'root',
})
export class HospitalStaffBankUnavailabilityPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IHospitalStaffBankUnavailabilityEntity,
  IPaginatedResponse<IHospitalStaffBankUnavailabilityEntity>
> {
  protected readonly endpoint = 'hospitalStaffBankUnavailability';

  export(queryParams: IQueryParams) {
    return this.retrieve<ICountEntity>(queryParams, {
      controllerResource: 'exportStaffBankUnavailability',
      skipSerializer: true,
    });
  }
}
