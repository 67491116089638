import { createAlertStateAdapter, createPaginatedStateAdapter } from '@locumsnest/core/src';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import { MessageableFactory, SignalableFactory } from '@locumsnest/core/src/lib/ngrx';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IPreferredRosterEntity } from '../../interfaces/api/preferred-roster-entity';

export const adapter: EntityAdapter<IPreferredRosterEntity> =
  createEntityAdapter<IPreferredRosterEntity>({});

export const messageableFactory =
  MessageableFactory.forFeature<'PreferredRoster'>('PreferredRoster');
export const loadingAdapter = createLoadingStateAdapter<'PreferredRoster'>(messageableFactory);
export const signalableFactory = SignalableFactory.forFeature<'PreferredRoster'>('PreferredRoster');

export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler, alertHandler } =
  alertStateAdapter.getAlertHandlers();
export const { ResetErrorMessage, AlertErrorMessage } = alertStateAdapter.getMessages();

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedPreferredRosterId: null,
});
