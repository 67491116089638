import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IPreferredWardEntity } from '../../interfaces/api/preferred-ward-entity';

@Injectable({
  providedIn: 'root',
})
export class PreferredWardPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IPreferredWardEntity
> {
  protected readonly endpoint = 'preferredWard';
}
