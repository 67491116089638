import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IExternalStaffingCandidateBidStatusEntity } from '../../interfaces/api/external-staffing-candidate-bid-status-entity';

export const adapter: EntityAdapter<IExternalStaffingCandidateBidStatusEntity> =
  createEntityAdapter<IExternalStaffingCandidateBidStatusEntity>({
    selectId: (externalStaffingCandidateBidStatus: IExternalStaffingCandidateBidStatusEntity) =>
      externalStaffingCandidateBidStatus.val,
  });

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedExternalStaffingCandidateBidStatusId: null,
});
