import { Action } from '@ngrx/store';
import { IExternalStaffingCandidateBidStatusEntity } from '../../interfaces/api/external-staffing-candidate-bid-status-entity';

export enum ExternalStaffingCandidateBidStatusSignalTypes {
  LOAD_STATUSES = '[ExternalStaffingCandidateBidStatus][Signal] Load Collection',
}
export enum ExternalStaffingCandidateBidStatusMessageTypes {
  SET_COLLECTION = '[ExternalStaffingCandidateBidStatus][Message] Set Collection',
  ADD_ONE = '[ExternalStaffingCandidateBidStatus][Message] Add One',
  UPDATE_ONE = '[ExternalStaffingCandidateBidStatus][Message] Update One',
  UPSERT_ONE = '[ExternalStaffingCandidateBidStatus][Message] Upsert One',
  DELETE_ONE = '[ExternalStaffingCandidateBidStatus][Message] Delete One',
  ADD_MULTIPLE = '[ExternalStaffingCandidateBidStatus][Message] Add All',
  DELETE_MULTIPLE = '[ExternalStaffingCandidateBidStatus][Message] Delete Many',
  UPSERT_MULTIPLE = '[ExternalStaffingCandidateBidStatus][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ExternalStaffingCandidateBidStatus][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ExternalStaffingCandidateBidStatusMessageTypes.SET_COLLECTION;
  constructor(
    public payload: {
      externalStaffingCandidateBidStatuses: IExternalStaffingCandidateBidStatusEntity[];
    }
  ) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ExternalStaffingCandidateBidStatusMessageTypes.UPSERT_ONE;

  constructor(
    public payload: {
      externalStaffingCandidateBidStatus: IExternalStaffingCandidateBidStatusEntity;
    }
  ) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ExternalStaffingCandidateBidStatusMessageTypes.UPSERT_MULTIPLE;

  constructor(
    public payload: {
      externalStaffingCandidateBidStatuses: IExternalStaffingCandidateBidStatusEntity[];
    }
  ) {}
}

export type ExternalStaffingCandidateBidStatusMessages =
  | SetCollectionMessage
  | UpsertOneMessage
  | UpsertMultipleMessage;
