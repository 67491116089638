import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IPreferredRosterEntity } from '../../interfaces/api/preferred-roster-entity';

@Injectable({
  providedIn: 'root',
})
export class PreferredRosterPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IPreferredRosterEntity
> {
  protected readonly endpoint = 'preferredRoster';
}
