import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IJobListingEntity } from '../../interfaces/api/job-listing-entity';
import { paginationAdapter } from './job-listing.adapter';

export const JobListingPaginationMessages = paginationAdapter.getMessages();

export class UpsertJobListingPageMessage extends JobListingPaginationMessages.UpsertPageMessage {}
export class UpsertJobListingMultiplePagesMessage extends JobListingPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetJobListingPaginationMessage extends JobListingPaginationMessages.ResetPaginationMessage {}

export enum JobListingSignalTypes {
  LOAD_JOB_LISTINGS = '[JobListing][Signal] Load Collection',
}
export enum JobListingMessageTypes {
  SET_COLLECTION = '[JobListing][Message] Set Collection',
  ADD_ONE = '[JobListing][Message] Add One',
  UPDATE_ONE = '[JobListing][Message] Update One',
  UPSERT_ONE = '[JobListing][Message] Upsert One',
  DELETE_ONE = '[JobListing][Message] Delete One',
  ADD_MULTIPLE = '[JobListing][Message] Add All',
  DELETE_MULTIPLE = '[JobListing][Message] Delete Many',
  UPSERT_MULTIPLE = '[JobListing][Message] Upsert Many',
  UPDATE_MULTIPLE = '[JobListing][Message] Update Many',
  SET_SELECTED = '[JobListing][Message] Set Selected',
}
export class LoadJobListingsSignal implements Action {
  readonly type = JobListingSignalTypes.LOAD_JOB_LISTINGS;

  constructor(public payload: { entities: IJobListingEntity[] }) {}
}
export class AddJobFragmentFormControlSignal implements Action {
  readonly type = JobListingSignalTypes.LOAD_JOB_LISTINGS;

  constructor(public payload: { entities: IJobListingEntity[] }) {}
}
export class SetCollectionMessage implements Action {
  readonly type = JobListingMessageTypes.SET_COLLECTION;

  constructor(public payload: { entities: IJobListingEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = JobListingMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IJobListingEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = JobListingMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IJobListingEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = JobListingMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IJobListingEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = JobListingMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IJobListingEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = JobListingMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IJobListingEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = JobListingMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IJobListingEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = JobListingMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = JobListingMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export class SetSelectedMessage implements Action {
  readonly type = JobListingMessageTypes.SET_SELECTED;

  constructor(public payload: { id: number }) {}
}

export type JobListingMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | SetSelectedMessage;

export type JobListingSignals = LoadJobListingsSignal;

export type JobListingActions = JobListingMessages | JobListingSignals;
