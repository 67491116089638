import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';

import { IExternalStaffingCandidateBidStatusEntity } from '../../interfaces/api/external-staffing-candidate-bid-status-entity';
import { selectAll, selectEntities } from './external-staffing-candidate-bid-status.reducer';
import {
  IExternalStaffingCandidateBidStatusEntityState,
  IExternalStaffingCandidateBidStatusFeatureState,
  IExternalStaffingCandidateBidStatusSharedState,
} from './interfaces';

export const getEntityState = (state: {
  entityState: IExternalStaffingCandidateBidStatusEntityState;
}) => state.entityState;

export const getExternalStaffingCandidateBidStatus =
  (externalStaffingCandidateBidStatusId: number) =>
  (externalStaffingCandidateBidStatuses: IExternalStaffingCandidateBidStatusEntity[]) => {
    const externalStaffingCandidateBidStatus = externalStaffingCandidateBidStatuses.find(
      (status) => status.val === externalStaffingCandidateBidStatusId
    );

    if (externalStaffingCandidateBidStatus) {
      return externalStaffingCandidateBidStatus.display;
    }
    return '';
  };

export const getExternalStaffingCandidateBidStatusCodeById =
  (externalStaffingCandidateBidStatusId: number) =>
  (externalStaffingCandidateBidStatuses: IExternalStaffingCandidateBidStatusEntity[]) => {
    const externalStaffingCandidateBidStatus = externalStaffingCandidateBidStatuses.find(
      (status) => status.val === externalStaffingCandidateBidStatusId
    );

    if (externalStaffingCandidateBidStatus) {
      return externalStaffingCandidateBidStatus.code;
    }
    return '';
  };

export const getExternalStaffingCandidateBidStatusOptions = (
  externalStaffingCandidateBidStatuses: IExternalStaffingCandidateBidStatusEntity[]
): ISelectOption[] =>
  externalStaffingCandidateBidStatuses
    .map((externalStaffingCandidateBidStatus) => ({
      id: externalStaffingCandidateBidStatus.val,
      name: externalStaffingCandidateBidStatus.display + ' Cascades',
    }))
    .filter((options) => options && options.name !== 'Cancelled Cascades');

export const getFilterExternalStaffingCandidateBidStatusOptions = (
  externalStaffingCandidateBidStatuses: IExternalStaffingCandidateBidStatusEntity[]
): ISelectOption[] =>
  externalStaffingCandidateBidStatuses
    .filter(({ isAsync }) => !isAsync)
    .map((externalStaffingCandidateBidStatus) => ({
      id: externalStaffingCandidateBidStatus.val,
      name: externalStaffingCandidateBidStatus.display,
    }));

export const getExternalStaffingCandidateBidStatusCompletedIds = (
  externalStaffingCandidateBidStatuses: IExternalStaffingCandidateBidStatusEntity[]
) =>
  externalStaffingCandidateBidStatuses
    .filter((options) => options && (options.code === 'APPROVED' || options.code === 'COMPLETED'))
    .map((status) => status.val);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectExternalStaffingCandidateBidStatusState = createFeatureSelector<
  IExternalStaffingCandidateBidStatusFeatureState & IExternalStaffingCandidateBidStatusSharedState
>('externalStaffingCandidateBidStatuses');
export const selectExternalStaffingCandidateBidStatusEntityState = createSelector(
  selectExternalStaffingCandidateBidStatusState,
  getEntityState
);

export const selectAllExternalStaffingCandidateBidStatuses = createSelector(
  selectExternalStaffingCandidateBidStatusEntityState,
  selectAll
);
export const selectExternalStaffingCandidateBidStatusDict = createSelector(
  selectExternalStaffingCandidateBidStatusEntityState,
  selectEntities
);

export const selectExternalStaffingCandidateBidStatus = (id) =>
  createSelector(selectExternalStaffingCandidateBidStatusDict, (entities) => entities[id]);
export const selectExternalStaffingCandidateBidStatusDisplay = (
  externalStaffingCandidateBidStatusId
) =>
  createSelector(
    selectAllExternalStaffingCandidateBidStatuses,
    getExternalStaffingCandidateBidStatus(externalStaffingCandidateBidStatusId)
  );

export const selectExternalStaffingCandidateBidStatusCodeById = (
  externalStaffingCandidateBidStatusId
) =>
  createSelector(
    selectAllExternalStaffingCandidateBidStatuses,
    getExternalStaffingCandidateBidStatusCodeById(externalStaffingCandidateBidStatusId)
  );

export const selectExternalStaffingCandidateBidStatusOptions = createSelector(
  selectAllExternalStaffingCandidateBidStatuses,
  getExternalStaffingCandidateBidStatusOptions
);

export const selectFilterExternalStaffingCandidateBidStatusOptions = createSelector(
  selectAllExternalStaffingCandidateBidStatuses,
  getFilterExternalStaffingCandidateBidStatusOptions
);

export const selectExternalStaffingCandidateBidStatusCompletedIds = createSelector(
  selectAllExternalStaffingCandidateBidStatuses,
  getExternalStaffingCandidateBidStatusCompletedIds
);
