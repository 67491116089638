import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ISelectOption } from '@locumsnest/components';

import { IApplicationStatusEntity } from '../../interfaces/api/application-status-entity';
import { selectAll, selectEntities } from './application-status.reducer';
import {
  IApplicationStatusEntityState,
  IApplicationStatusFeatureState,
  IApplicationStatusSharedState,
} from './interfaces';

export const getEntityState = (state: { entityState: IApplicationStatusEntityState }) =>
  state.entityState;

export const getApplicationStatusOptions = (appStatuses: IApplicationStatusEntity[]) =>
  appStatuses
    .filter((x) => !x.isAsync)
    .map((appStatus) => ({ id: appStatus.val, name: appStatus.display }));

export const getApplicationStatus = (val: number) => (statuses: IApplicationStatusEntity[]) =>
  statuses.find((status) => status.val === val);

export const getFilterApplicationStatusOptions = (
  applicationStatuses: IApplicationStatusEntity[]
): ISelectOption[] =>
  applicationStatuses
    .filter((x) => x.val > 0 && !x.isAsync)
    .map((applicationStatus) => ({ id: applicationStatus.val, name: applicationStatus.display }));

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectApplicationStatusState = createFeatureSelector<
  IApplicationStatusFeatureState & IApplicationStatusSharedState
>('applicationStatuses');

export const selectApplicationStatusEntityState = createSelector(
  selectApplicationStatusState,
  getEntityState
);

export const selectAllApplicationStatuses = createSelector(
  selectApplicationStatusEntityState,
  selectAll
);

export const selectApplicationStatusDict = createSelector(
  selectApplicationStatusEntityState,
  selectEntities
);

export const selectApplicationStatusOptions = createSelector(
  selectAllApplicationStatuses,
  getApplicationStatusOptions
);

export const selectFilterApplicationStatusOptions = createSelector(
  selectAllApplicationStatuses,
  getFilterApplicationStatusOptions
);
export const selectApplicationStatus = (id) =>
  createSelector(selectApplicationStatusDict, (entities) => entities[id]);
