import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { FilterStateService } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';

import { PRODUCT_CODES } from '../../core/constants';
import { IWardEntity } from '../../interfaces/api/ward-entity';
import {
  ResetWardPaginationMessage,
  UpsertMultipleMessage,
  UpsertMultiplePagesMessage,
  UpsertWardPageMessage,
  WardPaginationMessages,
} from './ward.messages';
import { WardPersistenceService } from './ward.persistence.service';
import { selectWardEntityState, wardPaginationSelectors } from './ward.selectors';

@Injectable({
  providedIn: 'root',
})
export class WardService extends FilterStateService<
  IWardEntity,
  UpsertWardPageMessage,
  ResetWardPaginationMessage,
  UpsertMultipleMessage
> {
  protected readonly scope = [
    PRODUCT_CODES.MATCH,
    PRODUCT_CODES.LINK,
    PRODUCT_CODES.INTELLIGENCE,
    PRODUCT_CODES.COMMUNITY,
    PRODUCT_CODES.PASSPORT_PLUS,
  ];

  constructor(
    protected store: Store,
    protected persistenceService: WardPersistenceService,
    protected microAppService: MicroAppService
  ) {
    super();
  }

  get paginationMessages() {
    return WardPaginationMessages;
  }

  get upsertMessageClass() {
    return UpsertWardPageMessage;
  }
  get clearMessageClass() {
    return ResetWardPaginationMessage;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get upsertMultiplePagesMessage() {
    return UpsertMultiplePagesMessage;
  }

  get paginationSelectors() {
    return wardPaginationSelectors;
  }

  get entityStateSelector() {
    return selectWardEntityState;
  }

  fetch() {
    return this.loadAllPages('default', null);
  }

  getAllActive() {
    return this.getAll().pipe(map((wards) => wards.filter(({ active }) => active)));
  }
}
