import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IWardEntityState, IWardFeatureState, IWardSharedState } from './interfaces';
import { loadingAdapter, paginationAdapter } from './ward.adapter';
import { selectAll } from './ward.reducer';

export const getEntityState = (state: { entityState: IWardEntityState }) => state.entityState;

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectWardState = createFeatureSelector<IWardFeatureState & IWardSharedState>('wards');

export const selectWardEntityState = createSelector(selectWardState, getEntityState);

export const selectAllWards = createSelector(selectWardEntityState, selectAll);

export const loadingStateSelectors = loadingAdapter.getSelectors(selectWardState);

export const wardPaginationSelectors = paginationAdapter.paginationSelectors(
  selectWardState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);
