import { Injectable } from '@angular/core';
import { isString } from 'lodash-es';
import { map } from 'rxjs';

import {
  defferSerialization,
  HttpApiPersistenceService,
  IPaginatedResponse,
  IQueryParams,
  ISerializedApiService,
} from '@locumsnest/core/src';
import {
  getEntityFields,
  IField,
  IFieldMap,
  ListSerializer,
  Serializer,
} from '@locumsnest/core/src/lib/serializers';
import { getDateFromDateString, getNaiveDateString } from '@locumsnest/core/src/lib/types';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { ICountEntity } from '../../interfaces/api/count-entity';
import {
  BulkUnpublishJobListingPayload,
  IJobFragmentEntity,
  IJobListingBookingStats,
  IJobListingEntity,
  IJobListingGradeEntity,
  IJobListingNote,
  ITimeFragmentEntity,
  JobListingNotePostPayload,
} from '../../interfaces/api/job-listing-entity';
import { UpsertMultipleMessage } from './job-listing.actions';

// @deprecated (applicable only for job listing until further cleanup operation)
export const deprecatedDateField: IField<Date, string> = {
  deserialize: getDateFromDateString,
  serialize(d) {
    return isString(d) ? d : getNaiveDateString(d);
  },
};
const DEPRECATED_BASE_FIELDS: IFieldMap<{ createdAt: Date; lastUpdate: Date }> = {
  createdAt: deprecatedDateField,
  lastUpdate: deprecatedDateField,
};
const timeFragmentSerializer = new Serializer(
  getEntityFields<ITimeFragmentEntity>(
    {
      fromTime: deprecatedDateField,
      toTime: deprecatedDateField,
    },
    {},
  ),
);
const jobFragmentSerializer = new Serializer(
  getEntityFields<IJobFragmentEntity>({ timeFragment: timeFragmentSerializer }, {}),
);
const listingGradeSerializer = new Serializer(
  getEntityFields<IJobListingGradeEntity>(
    {
      jobFragments: new ListSerializer(jobFragmentSerializer),
    },
    {},
  ),
);
const ENDPOINT = defferSerialization({ endpoint: 'jobListing' });

@Injectable({
  providedIn: 'root',
})
export class JobListingPersistenceService
  extends HttpApiPersistenceService<LocumsNestEndpointConfig, IJobListingEntity>
  implements ISerializedApiService<'jobListing', IJobListingEntity>
{
  readonly endpoint = ENDPOINT;
  readonly serializer = new Serializer(
    getEntityFields<IJobListingEntity<Date, number, number, number>>({
      startTime: deprecatedDateField,
      endTime: deprecatedDateField,
      applicationDeadline: deprecatedDateField,
      grades: new ListSerializer(listingGradeSerializer),
    }),
  );

  export(queryParams: IQueryParams) {
    return this.retrieve<ICountEntity>(queryParams, {
      controllerResource: 'exportShifts',
      skipSerializer: true,
    });
  }

  loadBookingStats(listingIds: number[]) {
    return this.retrieve<IPaginatedResponse<IJobListingBookingStats>>(
      { listing: listingIds },
      {
        controllerResource: 'jobListingBookingStats',
        skipSerializer: true,
      },
    );
  }

  publish(ids: number[]) {
    const data = {
      job_listings: ids,
    };

    return this.create<Record<string, unknown>, IJobListingEntity[]>(data, {
      controllerResource: 'publish',
      skipSerializer: true,
    }).pipe(map((response) => new UpsertMultipleMessage({ entities: response })));
  }

  escalate(ids: number[]) {
    const data = {
      job_listings: ids,
    };

    return this.create<{}, IJobListingEntity[]>(data, {
      controllerResource: 'escalate',
      skipSerializer: true,
    }).pipe(map((response) => new UpsertMultipleMessage({ entities: response })));
  }

  removeEscalation(ids: number[]) {
    const data = {
      job_listings: ids,
    };

    return this.create<{}, IJobListingEntity[]>(data, {
      controllerResource: 'removeEscalation',
      skipSerializer: true,
    }).pipe(map((response) => new UpsertMultipleMessage({ entities: response })));
  }

  escalateForAgencies(ids: number[]) {
    const data = {
      job_listings: ids,
    };

    return this.create<{}, IJobListingEntity[]>(data, {
      controllerResource: 'escalateForAgencies',
      skipSerializer: true,
    }).pipe(map((response) => new UpsertMultipleMessage({ entities: response })));
  }

  removeEscalationForAgencies(ids: number[]) {
    const data = {
      job_listings: ids,
    };

    return this.create<{}, IJobListingEntity[]>(data, {
      controllerResource: 'removeEscalationForAgencies',
      skipSerializer: true,
    }).pipe(map((response) => new UpsertMultipleMessage({ entities: response })));
  }

  unpublish(ids: number[]) {
    const data = {
      job_listings: ids,
    };

    return this.create<{}, IJobListingEntity[]>(data, {
      controllerResource: 'unpublish',
      skipSerializer: true,
    }).pipe(map((response) => new UpsertMultipleMessage({ entities: response })));
  }

  bulkUnpublish(listings: BulkUnpublishJobListingPayload[]) {
    return this.create<
      {},
      { successfullyUnpublishedListings: number; unsuccessfullyUnpublishedListings: number }
    >(listings, { controllerResource: 'bulkUnpublish', skipSerializer: true });
  }

  lockShiftRates(ids: number[]) {
    const data = {
      job_listings: ids,
    };

    return this.create<{}, IJobListingEntity[]>(data, {
      controllerResource: 'lockRates',
      skipSerializer: true,
    }).pipe(map((response) => new UpsertMultipleMessage({ entities: response })));
  }

  unlockShiftRates(ids: number[]) {
    const data = {
      job_listings: ids,
    };

    return this.create<{}, IJobListingEntity[]>(data, {
      controllerResource: 'unlockRates',
      skipSerializer: true,
    }).pipe(map((response) => new UpsertMultipleMessage({ entities: response })));
  }

  submitForAuthorization(ids: number[]) {
    const data = {
      job_listings: ids,
    };

    return this.create<{}, IJobListingEntity[]>(data, {
      controllerResource: 'submitForAuthorization',
      skipSerializer: true,
    }).pipe(map((response) => new UpsertMultipleMessage({ entities: response })));
  }

  bulkCreate(listings) {
    return this.create(listings, {
      controllerResource: 'listingsBulkCreate',
      skipSerializer: true,
    });
  }

  declineAllAndUnpublish(id: number) {
    return this.create<{}, IJobListingEntity>(
      {},
      {
        pathParams: { id },
        controllerResource: 'declineAllAndUnpublish',
        skipSerializer: true,
      },
    );
  }

  addListingNote(jobListing: number, noteText: string) {
    const data = {
      note: {
        noteText,
        notificationReceivers: [],
      },
      jobListing,
    };

    return this.create<{}, IJobListingEntity[]>(data, {
      controllerResource: 'listingNotes',
      skipSerializer: true,
    });
  }

  bulkCreateListingNotes(listings: JobListingNotePostPayload[]) {
    return this.create(listings, {
      controllerResource: 'listingNotesBulkCreate',
      skipSerializer: true,
    });
  }

  latestListingNotes(listing: number, pageSize: number = 1) {
    const data = {
      listing,
      pageSize,
    };

    return this.retrieve<IPaginatedResponse<IJobListingNote>>(data, {
      controllerResource: 'listingNotes',
      skipSerializer: true,
    });
  }
}
