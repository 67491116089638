import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory =
  SignalableFactory.forFeature<'PreferredRosterEntity'>('PreferredRosterEntity');

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class AddSelectedPreferredRosterTempSignal extends actionableFactory.create<
  'Add Selected Preferred Roster Temp Signal',
  { id: number; wardId: number }
>('Add Selected Preferred Roster Temp Signal') {}

export class RemoveSelectedPreferredRosterTempSignal extends actionableFactory.create<
  'Remove Selected Preferred Roster Temp Signal',
  { id: number; wardId: number }
>('Remove Selected Preferred Roster Temp Signal') {}

export class ClearAllPreferredRostersTempSignal extends actionableFactory.create<'Clear All Preferred Roster Temp Signal'>(
  'Clear All Preferred Roster Temp Signal'
) {}

export class SelectAllPreferredRostersTempSignal extends actionableFactory.create<'Select All Preferred Rosters Temp Signal'>(
  'Select All Preferred Rosters Temp Signal'
) {}

export class ClearAllUnassignedPreferredRostersTempSignal extends actionableFactory.create<'Clear All Unassigned Preferred Roster Temp Signal'>(
  'Clear All Unassigned Preferred Roster Temp Signal'
) {}

export class SelectAllUnassignedPreferredRostersTempSignal extends actionableFactory.create<'Select All Unassigned Preferred Rosters Temp Signal'>(
  'Select All Unassigned Preferred Rosters Temp Signal'
) {}

export class SelectAllPreferredRostersForSelectedWardTempSignal extends actionableFactory.create<
  'Select All Preferred Rosters For Selected Ward Temp Signal',
  Record<string, never>
>('Select All Preferred Rosters For Selected Ward Temp Signal') {}

export class ClearAllPreferredRostersForSelectedWardTempSignal extends actionableFactory.create<
  'Clear All Preferred Rosters For Selected Ward Temp Signal',
  Record<string, never>
>('Clear All Preferred Rosters For Selected Ward Temp Signal') {}

export type PreferredRosterSignals = InitializeCollectionSignal;
