import {
  ExternalStaffingCandidateBidStatusMessageTypes as MessageTypes,
  ExternalStaffingCandidateBidStatusMessages,
} from './external-staffing-candidate-bid-status.messages';
import { IExternalStaffingCandidateBidStatusEntityState } from './interfaces';
import { adapter, initialState } from './external-staffing-candidate-bid-status.adapter';
import { combineReducers } from '@ngrx/store';

export * from './interfaces';

export function entityReducer(
  state = initialState,
  action: ExternalStaffingCandidateBidStatusMessages
): IExternalStaffingCandidateBidStatusEntityState {
  switch (action.type) {
    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.externalStaffingCandidateBidStatus, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.externalStaffingCandidateBidStatuses, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.externalStaffingCandidateBidStatuses, state);
    }

    default: {
      return state;
    }
  }
}
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export function reducer(state, action) {
  return combineReducers({
    entityState: entityReducer,
  })(state, action);
}
