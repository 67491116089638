import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IApplicationStatusEntity } from '../../interfaces/api/application-status-entity';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IApplicationStatusEntity,
  IApplicationStatusEntity[]
> {
  protected readonly endpoint = 'applicationStatus';
}
