import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IFilterUiState } from '../interfaces/filter-ui-state';

const getShowDepartmentSection = (filterUiState: IFilterUiState) =>
  filterUiState.showDepartmentSection;

const getSelectedCategoryId = (filterUiState: IFilterUiState) => filterUiState.selectedCategoryId;

const getSelectedFilterGroup = (filterUiState: IFilterUiState) => filterUiState.selectedFilterGroup;
const getSelectAllInProgress = (filterUiState: IFilterUiState) => filterUiState.selectAllInProgress;

// #region selectSelectors

export const selectFilterUiState = createFeatureSelector<IFilterUiState>('filterUi');

export const selectShowDepartmentSection = createSelector(
  selectFilterUiState,
  getShowDepartmentSection
);

export const selectSelectedFilterGroup = createSelector(
  selectFilterUiState,
  getSelectedFilterGroup
);

export const selectSelectedCategoryId = createSelector(selectFilterUiState, getSelectedCategoryId);

export const selectSelectAllInProgress = createSelector(
  selectFilterUiState,
  getSelectAllInProgress
);

// #endregion selectSelectors
