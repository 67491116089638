import { selectAll, selectAllTemp } from './preferred-roster.reducer';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import {
  IPreferredRosterFeatureState,
  IPreferredRosterSharedState,
  IPreferredRosterEntityState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './preferred-roster.adapter';
import { IPreferredRosterEntity } from '../../interfaces/api/preferred-roster-entity';

export const getEntityState = (state: { entityState: IPreferredRosterEntityState }) =>
  state.entityState;

export const getTempState = (state: { tempState: IPreferredRosterEntityState }) => state.tempState;

export const getPreferredRosterIds = (entities: IPreferredRosterEntity[]) =>
  entities.map((entity) => entity.roster);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectPreferredRosterState =
  createFeatureSelector<IPreferredRosterFeatureState & IPreferredRosterSharedState>(
    'preferredRosters'
  );

// Entity State
export const selectPreferredRosterEntityState = createSelector(
  selectPreferredRosterState,
  getEntityState
);

export const selectAllPreferredRosters = createSelector(
  selectPreferredRosterEntityState,
  selectAll
);

export const selectAllPreferredRosterIds = createSelector(
  selectAllPreferredRosters,
  getPreferredRosterIds
);

// Temp State
export const selectPreferredRosterTempState = createSelector(
  selectPreferredRosterState,
  getTempState
);

export const selectAllPreferredRostersTemp = createSelector(
  selectPreferredRosterTempState,
  selectAllTemp
);

export const selectAllPreferredRosterIdsTemp = getPreferredRosterIds;

export const loadingStateSelectors = loadingAdapter.getSelectors(selectPreferredRosterState);

export const preferredRosterPaginationSelectors = paginationAdapter.paginationSelectors(
  selectPreferredRosterState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);
