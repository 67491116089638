import { IFilterUiState } from '../interfaces/filter-ui-state';
import {
  FilterUiMessages,
  InitializeFilterMessage,
  SelectAllCompleteMessage,
  SelectAllInProgressMessage,
  UpdateSelectedCategoryMessage,
  UpdateSelectedFilterGroupMessage,
  UpdateShowDepartmentSectionMessage,
} from './filter-ui.messages';

export * from './filter-ui.selectors';

export const INITIAL_UI_STATE: IFilterUiState = {
  selectedFilterGroup: 'Profession',
  showDepartmentSection: false,
  selectedCategoryId: null,
  selectAllInProgress: false,
};

export function reducer(state: IFilterUiState = INITIAL_UI_STATE, action: FilterUiMessages) {
  switch (action.type) {
    case InitializeFilterMessage.TYPE:
      return { ...INITIAL_UI_STATE, ...action.payload };
    case UpdateShowDepartmentSectionMessage.TYPE:
      state = {
        ...state,
        showDepartmentSection: !state.showDepartmentSection,
        selectedCategoryId: (action as UpdateShowDepartmentSectionMessage).payload
          .selectedCategoryId,
      };
      break;
    case UpdateSelectedFilterGroupMessage.TYPE:
      state = {
        ...state,
        selectedFilterGroup: (action as UpdateSelectedFilterGroupMessage).payload
          .selectedFilterGroup,
        showDepartmentSection: false,
        selectedCategoryId: null,
      };
      break;
    case UpdateSelectedCategoryMessage.TYPE:
      state = {
        ...state,
        selectedCategoryId: (action as UpdateSelectedCategoryMessage).payload.selectedCategoryId,
      };
      break;
    case SelectAllInProgressMessage.TYPE:
      state = {
        ...state,
        selectAllInProgress: true,
      };
      break;
    case SelectAllCompleteMessage.TYPE:
      state = {
        ...state,
        selectAllInProgress: false,
      };
      break;

    default:
      break;
  }

  return state;
}
