import { Action } from '@ngrx/store';
import { MessageableFactory } from '../../ngrx';

export const DEFAULT_INITIAL_LOADING_STATE = {};

export function createSingletonResourceStateFactory<F extends string, I extends string | number, T>(
  messageableFactory: MessageableFactory<F>,
  id: string
) {
  class SetValueForResource extends messageableFactory.create<
    string,
    { resourceId: I; subResource: T }
  >(`Set Value for ${id}`) {}
  class SetValueForResourceList extends messageableFactory.create<
    string,
    { resourceId: I; subResource: T }[]
  >(`Set Multiple Values for ${id}`) {}
  function createReducer() {
    const singletonResourceReducer = (
      state = DEFAULT_INITIAL_LOADING_STATE as Record<I, T>,
      action: SetValueForResource | (Action & {})
    ): Record<I, T> => {
      switch (action.type) {
        case SetValueForResource.TYPE: {
          const { resourceId, subResource } = (action as SetValueForResource).payload;
          return {
            ...state,
            [resourceId]: subResource,
          };
        }
        case SetValueForResourceList.TYPE: {
          const actionPayload = (action as SetValueForResourceList).payload;
          for (const payload of actionPayload) {
            state = singletonResourceReducer(state, new SetValueForResource(payload));
          }
        }
      }
      return state;
    };
    return singletonResourceReducer;
  }
  function getMessages() {
    return {
      SetValueForResource,
      SetValueForResourceList,
    };
  }

  return {
    createReducer,
    getMessages,
  };
}
