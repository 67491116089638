import { Injectable } from '@angular/core';
import { IQueryParams } from '@locumsnest/core/src';
import { buildQueryParams } from '@locumsnest/core/src/lib/helpers/util';
import { HttpEndpointService } from '@locumsnest/core/src/lib/http/http-endpoint.service';
import { LocumsNestEndpointConfig } from '../../constants';

@Injectable()
export class FileDownloaderService {
  constructor(private httpEndpointService: HttpEndpointService<LocumsNestEndpointConfig>) {}

  public download(endpointKey: keyof LocumsNestEndpointConfig, queryParams: IQueryParams) {
    let url = this.getFullEndpoint(endpointKey);
    url += this.addQueryParams(queryParams);
    window.location.href = url;
  }

  private getFullEndpoint(endpointKey: keyof LocumsNestEndpointConfig) {
    return this.httpEndpointService.getEndpoint(endpointKey);
  }

  private addQueryParams(queryParams: IQueryParams) {
    return buildQueryParams(queryParams);
  }
}
