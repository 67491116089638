import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import { StateService } from '@locumsnest/core';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { IHospitalStaffBankUnavailabilityReasonEntity } from '../../interfaces/api/hospital-staff-bank-unavailability-reason-entity';
import { SetCollectionMessage } from './hospital-staff-bank-unavailability-reason.messages';
import { HospitalStaffBankUnavailabilityReasonPersistenceService } from './hospital-staff-bank-unavailability-reason.persistence.service';
import {
  selectAllHospitalStaffBankUnavailabilityReasons,
  selectHospitalStaffBankUnavailabilityReasonOptions,
} from './hospital-staff-bank-unavailability-reason.selectors';

@Injectable({
  providedIn: 'root',
})
export class HospitalStaffBankUnavailabilityReasonService extends StateService<IHospitalStaffBankUnavailabilityReasonEntity> {
  constructor(
    private store: Store,
    protected persistenceService: HospitalStaffBankUnavailabilityReasonPersistenceService
  ) {
    super();
  }

  getAll() {
    return this.store.pipe(select(selectAllHospitalStaffBankUnavailabilityReasons), filterNonEmpty);
  }

  getHospitalStaffBankUnavailabilityReasonOptions() {
    return this.store.pipe(
      select(selectHospitalStaffBankUnavailabilityReasonOptions),
      filter((options) => options && options.length > 0)
    );
  }

  fetch() {
    return this.persistenceService
      .retrieve()
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }
}
