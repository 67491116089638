import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory =
  SignalableFactory.forFeature<'PreferredWardEntity'>('PreferredWardEntity');

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class AddSelectedPreferredWardTempSignal extends actionableFactory.create<
  'Add Selected Preferred Ward Temp Signal',
  { id: number }
>('Add Selected Preferred Ward Temp Signal') {}

export class RemoveSelectedPreferredWardTempSignal extends actionableFactory.create<
  'Remove Selected Preferred Ward Temp Signal',
  { id: number }
>('Remove Selected Preferred Ward Temp Signal') {}

export class ClearAllPreferredWardsTempSignal extends actionableFactory.create<'Clear All Preferred Ward Temp Signal'>(
  'Clear All Preferred Ward Temp Signal'
) {}

export class SelectAllPreferredWardsTempSignal extends actionableFactory.create<'Select All Preferred Wards Temp Signal'>(
  'Select All Preferred Wards Temp Signal'
) {}

export type PreferredWardSignals = InitializeCollectionSignal;
