export enum ApplicationStatusCodes {
  APPLICATION_RECEIVED = 'APPLICATION_RECEIVED',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  WITHDRAWN = 'WITHDRAWN',
  APPROVAL_PENDING_AUTHORIZATION = 'APPROVAL_PENDING_AUTHORIZATION',
  DECLINE_PENDING_AUTHORIZATION = 'DECLINE_PENDING_AUTHORIZATION',
  CANCELLATION_PENDING_AUTHORIZATION = 'CANCELLATION_PENDING_AUTHORIZATION',
  DECLINE_OUT_OF_SYNC = 'DECLINE_OUT_OF_SYNC',
  CANCELLATION_OUT_OF_SYNC = 'CANCELLATION_OUT_OF_SYNC',
}
