import { selectAll, selectAllTemp } from './preferred-ward.reducer';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import {
  IPreferredWardFeatureState,
  IPreferredWardSharedState,
  IPreferredWardEntityState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './preferred-ward.adapter';
import { IPreferredWardEntity } from '../../interfaces/api/preferred-ward-entity';

export const getEntityState = (state: { entityState: IPreferredWardEntityState }) =>
  state.entityState;

export const getPrefersUnassignedWards = (entityState: IPreferredWardEntityState) =>
  entityState.selectUnassigned;

export const getTempState = (state: { tempState: IPreferredWardEntityState }) => state.tempState;

export const getPreferredWardIds = (entities: IPreferredWardEntity[]) =>
  entities.map((entity) => entity.ward);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectPreferredWardState =
  createFeatureSelector<IPreferredWardFeatureState & IPreferredWardSharedState>('preferredWards');

// Entity State
export const selectPreferredWardEntityState = createSelector(
  selectPreferredWardState,
  getEntityState
);

export const selectAllPreferredWards = createSelector(selectPreferredWardEntityState, selectAll);

export const selectAllPreferredWardIds = createSelector(
  selectAllPreferredWards,
  getPreferredWardIds
);

// Temp State
export const selectPreferredWardTempState = createSelector(selectPreferredWardState, getTempState);

export const selectPrefersUnassignedWards = createSelector(
  selectPreferredWardEntityState,
  getPrefersUnassignedWards
);

export const selectAllPreferredWardsTemp = createSelector(
  selectPreferredWardTempState,
  selectAllTemp
);

export const selectPrefersUnassignedWardsTemp = createSelector(
  selectPreferredWardTempState,
  getPrefersUnassignedWards
);

export const selectAllPreferredWardIdsTemp = getPreferredWardIds;

export const loadingStateSelectors = loadingAdapter.getSelectors(selectPreferredWardState);

export const preferredWardPaginationSelectors = paginationAdapter.paginationSelectors(
  selectPreferredWardState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);
