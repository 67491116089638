import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AttributeStateService } from '@locumsnest/core/src';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { IApplicationStatusEntity } from '../../interfaces/api/application-status-entity';
import { SetCollectionMessage } from './application-status.messages';
import { ApplicationStatusPersistenceService } from './application-status.persistence.service';
import {
  selectAllApplicationStatuses,
  selectApplicationStatus,
  selectApplicationStatusOptions,
  selectFilterApplicationStatusOptions,
} from './application-status.selectors';
import { ApplicationStatusCodes } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusService extends AttributeStateService<IApplicationStatusEntity> {
  constructor(
    private store: Store,
    protected persistenceService: ApplicationStatusPersistenceService
  ) {
    super();
  }

  getOne(id: number) {
    return this.store.pipe(select(selectApplicationStatus(id)));
  }
  getAll() {
    return this.store.pipe(select(selectAllApplicationStatuses), filterNonEmpty);
  }

  getAllOptions() {
    return this.store.pipe(select(selectApplicationStatusOptions));
  }

  getApplicationStatus(val: number) {
    return this.store.pipe(select(selectApplicationStatus(val)));
  }

  getFilterApplicationStatusOptions() {
    return this.store.pipe(select(selectFilterApplicationStatusOptions));
  }

  getAlertStatusValues() {
    return this.getAllAfterLoading().pipe(
      map((statuses) => statuses.filter((x) => x.code === 'CANCELED').map((x) => x.val))
    );
  }
  getPendingStatus() {
    return this.getAllAfterLoading().pipe(
      map(
        (statuses) =>
          statuses.find((x) => x.code === ApplicationStatusCodes.APPLICATION_RECEIVED)?.val
      ),
      distinctUntilChanged()
    );
  }

  getAlertStatusValuesAsString() {
    return this.getAlertStatusValues().pipe(map((statuses) => statuses.map((x) => x.toString())));
  }

  fetch() {
    return this.persistenceService
      .retrieve()
      .pipe(map((results) => new SetCollectionMessage({ entities: results })));
  }
}
