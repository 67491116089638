import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IPreferredSpecialtyCategoryEntity } from '../../interfaces/api/preferred-specialty-category-entity';

@Injectable({
  providedIn: 'root',
})
export class PreferredSpecialtyCategoryPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IPreferredSpecialtyCategoryEntity
> {
  protected readonly endpoint = 'preferredSpecialtyCategory';
}
