import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IHospitalStaffBankUnavailabilityReasonEntity } from '../../interfaces/api/hospital-staff-bank-unavailability-reason-entity';

@Injectable({
  providedIn: 'root',
})
export class HospitalStaffBankUnavailabilityReasonPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IHospitalStaffBankUnavailabilityReasonEntity
> {
  protected readonly endpoint = 'hospitalStaffBankUnavailabilityReason';
}
