import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { ExternalStaffingCandidateBidStatusCodes } from '../+state/interfaces/codes';

/**
 * Returns alert type of ExternalStaffingCandidateBidStatus
 *@
 * @export
 * @class DateTime
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'bidStatusAlertType',
})
@Injectable()
export class ExternalStaffingCandidateBidStatusAlertTypePipe implements PipeTransform {
  constructor() {}
  transform(statusCode: string): string {
    switch (statusCode) {
      case ExternalStaffingCandidateBidStatusCodes.SUBMITTED:
      case ExternalStaffingCandidateBidStatusCodes.DECLINE_PENDING_AUTHORIZATION:
      case ExternalStaffingCandidateBidStatusCodes.APPROVAL_PENDING_AUTHORIZATION:
      case ExternalStaffingCandidateBidStatusCodes.CANCELLATION_PENDING_AUTHORIZATION:
        return 'warning';
      case ExternalStaffingCandidateBidStatusCodes.APPROVED:
      case ExternalStaffingCandidateBidStatusCodes.COMPLETED:
        return 'success';
      case ExternalStaffingCandidateBidStatusCodes.REJECTED:
      case ExternalStaffingCandidateBidStatusCodes.WITHDRAWN:
      case ExternalStaffingCandidateBidStatusCodes.CANCELED:
      case ExternalStaffingCandidateBidStatusCodes.EXPIRED:
      case ExternalStaffingCandidateBidStatusCodes.DECLINE_OUT_OF_SYNC:
      case ExternalStaffingCandidateBidStatusCodes.CANCELLATION_OUT_OF_SYNC:
        return 'error';
      default:
        return statusCode;
    }
  }
}

/**
 * Returns alert type of ExternalStaffingCandidateBidStatus
 *@
 * @export
 * @class DateTime
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'bidStatusAlertIcon',
})
@Injectable()
export class ExternalStaffingCandidateBidStatusAlertIcon implements PipeTransform {
  constructor() {}
  transform(statusCode: string): string {
    switch (statusCode) {
      case ExternalStaffingCandidateBidStatusCodes.DECLINE_OUT_OF_SYNC:
      case ExternalStaffingCandidateBidStatusCodes.CANCELLATION_OUT_OF_SYNC:
        return 'sync_failed';
      default:
        return '';
    }
  }
}
