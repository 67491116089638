import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IHospitalStaffBankUnavailabilityReasonEntity } from '../../interfaces/api/hospital-staff-bank-unavailability-reason-entity';

export function sortByName(
  a: IHospitalStaffBankUnavailabilityReasonEntity,
  b: IHospitalStaffBankUnavailabilityReasonEntity
): number {
  return a.staffbankUnavailabilityReason.display.localeCompare(
    b.staffbankUnavailabilityReason.display
  );
}

export const adapter: EntityAdapter<IHospitalStaffBankUnavailabilityReasonEntity> =
  createEntityAdapter<IHospitalStaffBankUnavailabilityReasonEntity>({
    sortComparer: sortByName,
  });

export const initialState = adapter.getInitialState({
  selectedHospitalStaffBankUnavailabilityReasonId: null,
});
