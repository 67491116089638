import {
  selectAll,
  IHospitalStaffBankUnavailabilityReasonEntityState,
  IHospitalStaffBankUnavailabilityReasonFeatureState,
  IHospitalStaffBankUnavailabilityReasonSharedState,
} from './hospital-staff-bank-unavailability-reason.reducer';
import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IHospitalStaffBankUnavailabilityReasonEntity } from '../../interfaces/api/hospital-staff-bank-unavailability-reason-entity';

export const getEntityState = (state: {
  entityState: IHospitalStaffBankUnavailabilityReasonEntityState;
}) => state.entityState;

export const getHospitalStaffBankUnavailabilityReasonOptions = (
  reasons: IHospitalStaffBankUnavailabilityReasonEntity[]
): ISelectOption[] =>
  reasons.map((reason) => ({
    id: reason.staffbankUnavailabilityReason.id,
    name: reason.staffbankUnavailabilityReason.display,
  }));

export const selectHospitalStaffBankUnavailabilityReasonState = createFeatureSelector<
  IHospitalStaffBankUnavailabilityReasonFeatureState &
    IHospitalStaffBankUnavailabilityReasonSharedState
>('hospitalStaffBankUnavailabilityReasons');

export const selectHospitalStaffBankUnavailabilityReasonEntityState = createSelector(
  selectHospitalStaffBankUnavailabilityReasonState,
  getEntityState
);
export const selectAllHospitalStaffBankUnavailabilityReasons = createSelector(
  selectHospitalStaffBankUnavailabilityReasonEntityState,
  selectAll
);
export const selectHospitalStaffBankUnavailabilityReasonOptions = createSelector(
  selectAllHospitalStaffBankUnavailabilityReasons,
  getHospitalStaffBankUnavailabilityReasonOptions
);
