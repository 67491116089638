import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IHospitalStaffBankUnavailabilityReasonEntity } from '../../interfaces/api/hospital-staff-bank-unavailability-reason-entity';

export enum HospitalStaffBankUnavailabilityReasonSignalTypes {
  LOAD_STAFFBANK_UNAVAILABILITY_REASONS = '[HSBUnavailabilityReason][Signal] Load Collection',
}
export enum HospitalStaffBankUnavailabilityReasonMessageTypes {
  SET_COLLECTION = '[HospitalStaffBankUnavailabilityReason][Message] Set Collection',
  ADD_ONE = '[HospitalStaffBankUnavailabilityReason][Message] Add One',
  UPDATE_ONE = '[HospitalStaffBankUnavailabilityReason][Message] Update One',
  UPSERT_ONE = '[HospitalStaffBankUnavailabilityReason][Message] Upsert One',
  DELETE_ONE = '[HospitalStaffBankUnavailabilityReason][Message] Delete One',
  ADD_MULTIPLE = '[HospitalStaffBankUnavailabilityReason][Message] Add All',
  DELETE_MULTIPLE = '[HospitalStaffBankUnavailabilityReason][Message] Delete Many',
  UPSERT_MULTIPLE = '[HospitalStaffBankUnavailabilityReason][Message] Upsert Many',
  UPDATE_MULTIPLE = '[HospitalStaffBankUnavailabilityReason][Message] Update Many',
}

export class SetCollectionMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityReasonMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IHospitalStaffBankUnavailabilityReasonEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityReasonMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IHospitalStaffBankUnavailabilityReasonEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityReasonMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IHospitalStaffBankUnavailabilityReasonEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityReasonMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IHospitalStaffBankUnavailabilityReasonEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityReasonMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IHospitalStaffBankUnavailabilityReasonEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityReasonMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IHospitalStaffBankUnavailabilityReasonEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityReasonMessageTypes.UPDATE_MULTIPLE;

  constructor(
    public payload: { entities: Update<IHospitalStaffBankUnavailabilityReasonEntity>[] }
  ) {}
}

export class DeleteOneMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityReasonMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = HospitalStaffBankUnavailabilityReasonMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type HospitalStaffBankUnavailabilityReasonMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
