import { createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

export function createSelectorFactory(statePath) {
  return {
    getSelectors<I extends string | number, T>(stateSelector) {
      const selectSubResourceState = createSelector(
        stateSelector,
        (state: unknown): Record<I, T> => get(state, statePath)
      );
      const selectSubResource = (id: I) =>
        createSelector(selectSubResourceState, (state: Record<I, T>): T => get(state, id));
      return {
        selectSubResourceState,
        selectSubResource,
      };
    },
  };
}
