import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, merge, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  IFilterCategoryPayload,
  IFilterGroup,
  IFilterSelectAllPayload,
  IOption,
} from '@locumsnest/components/src/lib/interfaces/filter';
import { FilterStateService } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';

import { PRODUCT_CODES } from '../../core/constants';
import { IPreferredWardEntity } from '../../interfaces/api/preferred-ward-entity';
import { IWardEntity } from '../../interfaces/api/ward-entity';
import {
  ClearAllUnassignedPreferredRostersTempSignal,
  SelectAllUnassignedPreferredRostersTempSignal,
} from '../../preferred-roster/+state/temp/preferred-roster.signals';
import { RosterService } from '../../roster/+state/roster.service';
import { FilterNamespace } from './../../../../../../libs/components/src/lib/interfaces/filter';
import { WardService } from './../../ward/+state/ward.service';
import { loadingAdapter } from './preferred-ward.adapter';
import {
  PreferredWardPaginationMessages,
  ResetPreferredWardPaginationMessage,
  UpsertMultipleMessage,
  UpsertPreferredWardPageMessage,
} from './preferred-ward.messages';
import { PreferredWardPersistenceService } from './preferred-ward.persistence.service';
import {
  preferredWardPaginationSelectors,
  selectAllPreferredWardIds,
  selectAllPreferredWardsTemp,
  selectPreferredWardEntityState,
  selectPrefersUnassignedWards,
  selectPrefersUnassignedWardsTemp,
} from './preferred-ward.selectors';
import * as tempMessages from './temp/preferred-ward-temp.messages';
import {
  AddSelectedPreferredWardTempSignal,
  ClearAllPreferredWardsTempSignal,
  RemoveSelectedPreferredWardTempSignal,
  SelectAllPreferredWardsTempSignal,
} from './temp/preferred-ward-temp.signals';

@Injectable({
  providedIn: 'root',
})
export class PreferredWardService extends FilterStateService<
  IPreferredWardEntity,
  UpsertPreferredWardPageMessage,
  ResetPreferredWardPaginationMessage,
  UpsertMultipleMessage
> {
  protected readonly scope = [
    PRODUCT_CODES.MATCH,
    PRODUCT_CODES.LINK,
    PRODUCT_CODES.INTELLIGENCE,
    PRODUCT_CODES.COMMUNITY,
  ];
  constructor(
    protected store: Store,
    protected persistenceService: PreferredWardPersistenceService,
    protected wardService: WardService,
    protected rosterService: RosterService,
    protected microAppService: MicroAppService
  ) {
    super();
  }

  get paginationMessages() {
    return PreferredWardPaginationMessages;
  }

  get paginationSelectors() {
    return preferredWardPaginationSelectors;
  }

  get entityStateSelector() {
    return selectPreferredWardEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }
  getAllTemp() {
    return this.isFilterEnabled$.pipe(
      switchMap((isFilterEnabled) => {
        if (isFilterEnabled) {
          return this.store.pipe(select(selectAllPreferredWardsTemp));
        }

        return of<IPreferredWardEntity[]>([]);
      })
    );
  }

  getAllTempWardsIds() {
    return this.getAllTemp().pipe(map((wards) => wards.map(({ ward }) => ward)));
  }

  getAllTempCount() {
    return this.getAllTemp().pipe(map((preferences) => preferences.length));
  }
  getTempExists() {
    return this.getAllTempCount().pipe(
      map((count) => !!count),
      distinctUntilChanged()
    );
  }

  getPreferredWardByWardId(wardId) {
    return this.getAllTemp().pipe(
      map((preferredWards) => preferredWards.find((x) => x.ward === wardId))
    );
  }

  getPreferredRostersByWardId(wardId) {
    return this.getPreferredWardByWardId(wardId).pipe(
      mergeMap((preferredWard) =>
        preferredWard ? this.rosterService.getActiveByWardId(preferredWard.ward) : of([])
      )
    );
  }

  getAlert() {
    return this.getTempExists().pipe(
      map((preferredWardExists) =>
        preferredWardExists
          ? '*Narrowing down by roster may return ' +
            'no data if you have the wrong professions selected.'
          : ''
      )
    );
  }

  bulkCreate(wards: { ward: number }[]) {
    return this.persistenceService.bulkCreateFilter(wards).pipe(
      map(
        (response: IPreferredWardEntity[]) =>
          new tempMessages.AddMultipleMessage({
            entities: response,
          })
      )
    );
  }

  getAllTempWardIds() {
    return this.getAllTemp().pipe(map((entities) => entities.map(({ ward }) => ward)));
  }

  bulkDelete(wards: { id: number[] }, persist = true) {
    if (persist === true) {
      return this.persistenceService.clear(wards).pipe(
        map(
          () =>
            new tempMessages.DeleteMultipleMessage({
              ids: wards.id,
            })
        )
      );
    }

    return of(
      new tempMessages.DeleteMultipleMessage({
        ids: wards.id,
      })
    );
  }

  clear() {
    return this.persistenceService
      .clear({})
      .pipe(map(() => new tempMessages.ResetCollectionMessage({})));
  }

  getAllIds() {
    return this.store.pipe(select(selectAllPreferredWardIds));
  }

  getAllIdsAfterLoading() {
    return this.isLoaded('default').pipe(
      filter((isLoaded) => isLoaded === true),
      mergeMap(() => this.getAllIds())
    );
  }
  getFilters() {
    return combineLatest([this.wardService.getAll(), this.getAllTempWardIds()]).pipe(
      map(([wards, preferredWardIds]: [IWardEntity[], number[]]): IFilterGroup => {
        let order = 0;
        const options = wards.map((ward) => {
          const option: IOption = {
            id: ward.id,
            name: ward.name,
            order: order++,
            visible: true,
            selected: preferredWardIds.includes(ward.id),
            parentId: null,
          };

          return option;
        });

        const filterGroup: IFilterGroup = {
          displayName: 'Ward',
          slug: 'Ward',
          count: options.filter((x) => x.selected).length,
          options: options.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)),
          namespace: FilterNamespace.default,
          optional: true,
        };

        return filterGroup;
      })
    );
  }

  getPrefersUnassignedWards() {
    return this.store.pipe(select(selectPrefersUnassignedWards));
  }

  getPrefersUnassignedWardsTemp() {
    return this.store.pipe(select(selectPrefersUnassignedWardsTemp));
  }

  fetch() {
    return merge(
      this.loadAllPages('default', null),
      this.isLoaded('default').pipe(
        filter((isLoaded) => isLoaded === true),
        first(),
        mergeMap(() =>
          this.getAll().pipe(map((entities) => new tempMessages.SetCollectionMessage({ entities })))
        )
      )
    );
  }

  dispatchToggleSignals(payload: IFilterCategoryPayload) {
    if (payload.id === -1) {
      if (!payload.selected) {
        return this.store.dispatch(new SelectAllUnassignedPreferredRostersTempSignal({}));
      } else {
        return this.store.dispatch(new ClearAllUnassignedPreferredRostersTempSignal({}));
      }
    }

    if (!payload.selected) {
      return this.store.dispatch(new AddSelectedPreferredWardTempSignal({ id: payload.id }));
    } else {
      return this.store.dispatch(new RemoveSelectedPreferredWardTempSignal({ id: payload.id }));
    }
  }

  dispatchToggleAllSignal(payload: IFilterSelectAllPayload) {
    if (payload.selected) {
      return this.store.dispatch(new SelectAllPreferredWardsTempSignal({}));
    } else {
      return this.store.dispatch(new ClearAllPreferredWardsTempSignal({}));
    }
  }
}
