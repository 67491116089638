import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IRosterEntity } from '../../interfaces/api/roster-entity';
import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';

export const adapter: EntityAdapter<IRosterEntity> = createEntityAdapter<IRosterEntity>({});
export const messageableFactory = MessageableFactory.forFeature<'Roster'>('Roster');
export const signalableFactory = SignalableFactory.forFeature<'Roster'>('Roster');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'Roster'>(messageableFactory);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedRosterId: null,
});
