import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPreferredSpecialtyCategoryEntity } from '../../interfaces/api/preferred-specialty-category-entity';
import { loadingAdapter, paginationAdapter } from './preferred-specialty-category.adapter';
import {
  IPreferredSpecialtyCategoryEntityState,
  IPreferredSpecialtyCategoryFeatureState,
  IPreferredSpecialtyCategorySharedState,
  selectAll,
  selectAllTemp,
} from './preferred-specialty-category.reducer';

export const getEntityState = (state: { entityState: IPreferredSpecialtyCategoryEntityState }) =>
  state.entityState;

export const getTempState = (state: { tempState: IPreferredSpecialtyCategoryEntityState }) =>
  state.tempState;

export const getPreferredSpecialtyCategoryIds = (state: IPreferredSpecialtyCategoryEntityState) =>
  (state.ids as number[]).map((id: number) => state.entities[id].specialtyCategory);

export const getPreferredSpecialtyCategory =
  (specialtyCategoryId: number) =>
  (preferredSpecialtyCategories: IPreferredSpecialtyCategoryEntity[]) =>
    preferredSpecialtyCategories.find(
      (preferredSpecialtyCategory) => preferredSpecialtyCategory.id === specialtyCategoryId
    );

export const getBySpecialtyCategoryId =
  (specialtyCategoryId: number, professionId: number) =>
  (preferredSpecialtyCategories: IPreferredSpecialtyCategoryEntity[]) =>
    preferredSpecialtyCategories.find(
      ({ specialtyCategory, profession }) =>
        specialtyCategory === specialtyCategoryId && profession === professionId
    );

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectPreferredSpecialtyCategoryState = createFeatureSelector<
  IPreferredSpecialtyCategoryFeatureState & IPreferredSpecialtyCategorySharedState
>('preferredSpecialtyCategories');

// Entity State
export const selectPreferredSpecialtyCategoryEntityState = createSelector(
  selectPreferredSpecialtyCategoryState,
  getEntityState
);

export const selectAllPreferredSpecialtyCategories = createSelector(
  selectPreferredSpecialtyCategoryEntityState,
  selectAll
);

export const selectAllPreferredSpecialtyCategoryIds = createSelector(
  selectPreferredSpecialtyCategoryEntityState,
  getPreferredSpecialtyCategoryIds
);

// Temp State
export const selectPreferredSpecialtyCategoryTempState = createSelector(
  selectPreferredSpecialtyCategoryState,
  getTempState
);

export const selectAllPreferredSpecialtyCategoriesTemp = createSelector(
  selectPreferredSpecialtyCategoryTempState,
  selectAllTemp
);

export const selectAllPreferredSpecialtyCategoryIdsTemp = createSelector(
  selectPreferredSpecialtyCategoryTempState,
  getPreferredSpecialtyCategoryIds
);

export const selectPreferredSpecialtyCategory = (specialtyId) =>
  createSelector(selectAllPreferredSpecialtyCategories, getPreferredSpecialtyCategory(specialtyId));

export const selectBySpecialtyCategoryId = (categoryId: number, professionId: number) =>
  createSelector(
    selectAllPreferredSpecialtyCategories,
    getBySpecialtyCategoryId(categoryId, professionId)
  );

export const selectBySpecialtyCategoryIdTemp = (categoryId: number, professionId: number) =>
  createSelector(
    selectAllPreferredSpecialtyCategoriesTemp,
    getBySpecialtyCategoryId(categoryId, professionId)
  );

export const loadingStateSelectors = loadingAdapter.getSelectors(
  selectPreferredSpecialtyCategoryState
);

export const preferredSpecialtyCategoryPaginationSelectors = paginationAdapter.paginationSelectors(
  selectPreferredSpecialtyCategoryState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);
