import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { get } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { ISelectOption } from '@locumsnest/components';
import { StateService } from '@locumsnest/core/src';
import { filterNonEmpty } from '@locumsnest/core/src/lib/ngrx/operators';

import { selectAssignedHospitalSector } from '../../hospital/+state/hospital.selectors';
import { IGradeEntity } from '../../interfaces/api/grade-entity';
import { IProfessionEntity } from '../../interfaces/api/profession-entity';
import { ProfessionService } from '../../profession/+state/profession.service';
import { SectorService } from '../../sector/+state/sector.services';
import { SetCollectionMessage } from './grade.messages';
import { GradePersistenceService } from './grade.persistence.service';
import {
  selectAllGrades,
  selectFilterGradeOptions,
  selectGrade,
  selectGradeOptions,
  selectGradeProfession,
  selectNonPrimaryCareGrades,
  selectPrimaryCareGrades,
} from './grade.selectors';

@Injectable({
  providedIn: 'root',
})
export class GradeService extends StateService<IGradeEntity> {
  constructor(
    private store: Store,
    protected persistenceService: GradePersistenceService,
    private sectorService: SectorService,
    private professionService: ProfessionService,
  ) {
    super();
  }

  getAll() {
    return this.store.pipe(select(selectAllGrades), filterNonEmpty);
  }

  getAllAfterLoading() {
    return this.getAll().pipe(filter((x) => x && x.length > 0));
  }

  getGrade(gradeId: number) {
    return this.store.pipe(select(selectGrade(gradeId)));
  }

  getGradeProfession(gradeId: number) {
    return this.store.pipe(select(selectGradeProfession(gradeId)));
  }

  getFilterGradeOptions() {
    return this.store.pipe(select(selectFilterGradeOptions));
  }

  getGradeWithProfessionOptions() {
    return combineLatest([
      this.getAll(),
      this.professionService.getAll().pipe(filter((x) => !!x.length)),
    ]).pipe(
      map(([grades, professions]: [IGradeEntity[], IProfessionEntity[]]): ISelectOption[] =>
        grades
          .map((grade) => ({
            id: grade.id,
            name: grade.title,
            category: get(
              professions.find((x) => x.id === grade.profession),
              'title',
              null,
            ),
          }))
          .filter((x) => !!x.category),
      ),
    );
  }

  getAllOptions() {
    return this.store.pipe(select(selectGradeOptions));
  }

  getGradeOptionsWithSkipOption(professions: number[]) {
    return this.getHospitalProfessionGradeOptions(professions);
    // .pipe(
    //   map((options)=>[...options,{id:-1, name:'Skip for now'}])
    // );
  }

  getHospitalGrades() {
    return this.store.pipe(
      select(selectAssignedHospitalSector),
      mergeMap((hospitalSector) => this.sectorService.getOne(hospitalSector)),
      mergeMap((sector) => {
        if (sector && sector.code === 'PRIMARY_CARE')
          return this.store.pipe(select(selectPrimaryCareGrades));

        return this.store.pipe(select(selectNonPrimaryCareGrades));
      }),
    );
  }

  getHospitalProfessionGrades(professions: number[]) {
    return this.getHospitalGrades().pipe(
      map((grades) => grades.filter((grade) => professions.includes(grade.profession))),
    );
  }

  getHospitalProfessionGradeOptions(professions: number[]) {
    return combineLatest([this.getHospitalProfessionGrades(professions)]).pipe(
      map(([grades]: [IGradeEntity[]]): ISelectOption[] =>
        grades.map((grade) => ({
          id: grade.id,
          name: grade.title,
        })),
      ),
    );
  }

  getHospitalProfessionGradeIds(profession) {
    return this.getHospitalProfessionGrades([profession]).pipe(
      map((grades) => grades.map((grade) => grade.id)),
    );
  }

  fetch() {
    return this.persistenceService
      .retrieve({ pageSize: 1000 })
      .pipe(map(({ results: entities }) => new SetCollectionMessage({ entities })));
  }
}
