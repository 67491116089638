import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { IGradeEntity } from '../../interfaces/api/grade-entity';

export enum GradeSignalTypes {
  LOAD_GRADES = '[Grade][Signal] Load Collection',
}
export enum GradeMessageTypes {
  SET_COLLECTION = '[Grade][Message] Set Collection',
  ADD_ONE = '[Grade][Message] Add One',
  UPDATE_ONE = '[Grade][Message] Update One',
  UPSERT_ONE = '[Grade][Message] Upsert One',
  DELETE_ONE = '[Grade][Message] Delete One',
  ADD_MULTIPLE = '[Grade][Message] Add All',
  DELETE_MULTIPLE = '[Grade][Message] Delete Many',
  UPSERT_MULTIPLE = '[Grade][Message] Upsert Many',
  UPDATE_MULTIPLE = '[Grade][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = GradeMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IGradeEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = GradeMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IGradeEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = GradeMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IGradeEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = GradeMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IGradeEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = GradeMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IGradeEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = GradeMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IGradeEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = GradeMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IGradeEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = GradeMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = GradeMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type GradeMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
