/* eslint-disable @typescript-eslint/naming-convention */
import { ISelectOption } from '@locumsnest/components';
import { Time } from '@locumsnest/core/src/lib/helpers';

export interface FilterOption {
  id: number;
  name: string;
  display: string;
  default: boolean;
  order: number;
  params: { [key: string]: string };
}

function getTodayDate() {
  return Time.formatDBDate();
}

function getTodayDateISO() {
  return Time.formatISODate();
}

const CascadeFilters: FilterOption[] = [
  {
    id: 1,
    name: 'all-jobs',
    display: 'Cascades: All',
    default: true,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'authorised-cascades',
    display: 'Cascaded',
    default: false,
    order: 2,
    params: {
      staffing_cascade__isnull: 'false',
    },
  },
  {
    id: 3,
    name: 'pending-cascades',
    display: 'Pending Cascades',
    default: false,
    order: 3,
    params: {
      staffing_cascade__isnull: 'false',
      staffing_cascade__authorized_at__isnull: 'true',
    },
  },
  {
    id: 4,
    name: 'rejected-cascades',
    display: 'Rejected Cascades',
    default: false,
    order: 4,
    params: {
      staffing_cascade__isnull: 'false',
      staffing_cascade__authorized_at__isnull: 'true',
    },
  },
  {
    id: 5,
    name: 'not-yet-cascaded',
    display: 'Not Cascaded',
    default: false,
    order: 5,
    params: {
      staffing_cascade__isnull: 'true',
    },
  },
];

const ExportPageCascadeFilters: FilterOption[] = [
  {
    id: 1,
    name: 'Non-Cascaded Shifts',
    display: 'Non-Cascaded Shifts',
    default: true,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'Cascaded Shifts',
    display: 'Cascaded Shifts',
    default: true,
    order: 2,
    params: {},
  },
];

const PublishedFilters: FilterOption[] = [
  {
    id: 1,
    name: 'all',
    display: 'Published: All',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'yes',
    display: 'Published: Yes',
    default: true,
    order: 2,
    params: {
      published: 'true',
    },
  },
  {
    id: 3,
    name: 'no',
    display: 'Published: No',
    default: false,
    order: 3,
    params: {
      published: 'false',
    },
  },
  {
    id: 4,
    name: 'pending-authorisation',
    display: 'Pending Authorisation',
    default: false,
    order: 4,
    params: {
      published: 'null',
    },
  },
];

const EscalatedFilters: FilterOption[] = [
  {
    id: 1,
    name: 'all',
    display: 'Escalated: All',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'both',
    display: 'Escalated: Yes',
    default: false,
    order: 2,
    params: {
      shift_escalated: 'true',
    },
  },
  {
    id: 3,
    name: 'for bank',
    display: 'Escalated: For Bank',
    default: true,
    order: 3,
    params: {
      shift_escalated: 'true',
      shift_escalated_for_agencies: 'false',
    },
  },
  {
    id: 4,
    name: 'for agency',
    display: 'Escalated: For Agency',
    default: true,
    order: 4,
    params: {
      shift_escalated_for_agencies: 'true',
    },
  },
  {
    id: 5,
    name: 'no',
    display: 'Escalated: No',
    default: false,
    order: 5,
    params: {
      shift_escalated: 'false',
      shift_escalated_for_agencies: 'false',
    },
  },
];

const ExportPagePublishedFilters: FilterOption[] = [
  {
    id: 1,
    name: 'Published',
    display: 'Published',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'Not Published',
    display: 'Not Published',
    default: false,
    order: 2,
    params: {},
  },
  {
    id: 3,
    name: 'Pending Authorisation',
    display: 'Pending Authorisation',
    default: false,
    order: 3,
    params: {},
  },
];

const StatusFilters: FilterOption[] = [
  {
    id: 1,
    name: 'all-jobs',
    display: 'All Jobs',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'completed',
    display: 'Past jobs',
    default: false,
    order: 2,
    params: {
      end_time__lt: getTodayDateISO(),
    },
  },
  {
    id: 3,
    name: 'all-upcoming',
    display: 'All upcoming jobs',
    default: true,
    order: 3,
    params: {
      end_time__gte: getTodayDateISO(),
    },
  },
  {
    id: 4,
    name: 'upcoming-unfilled',
    display: 'Upcoming unfilled',
    default: false,
    order: 4,
    params: {
      filled: 'false',
      end_time__gte: getTodayDateISO(),
    },
  },
  {
    id: 5,
    name: 'upcoming-filled',
    display: 'Upcoming filled',
    default: false,
    order: 5,
    params: {
      filled: 'true',
      end_time__gte: getTodayDateISO(),
    },
  },
];

const ExportPageStatusFilters: FilterOption[] = [
  {
    id: 1,
    name: 'Filled',
    display: 'Filled',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'Unfilled',
    display: 'Unfilled',
    default: false,
    order: 2,
    params: {},
  },
];

const ExportPageStaffBankRegisteredFilters: FilterOption[] = [
  {
    id: 1,
    name: 'Yes',
    display: 'Yes',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'No',
    display: 'No',
    default: false,
    order: 2,
    params: {},
  },
];

const ExportPageTimesheetFormatFilters: FilterOption[] = [
  {
    id: 1,
    name: 'By Timesheet',
    display: 'By Timesheet',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'By Fragment',
    display: 'By Fragment',
    default: false,
    order: 2,
    params: {},
  },
];

const ExportPageAdhocFilters: FilterOption[] = [
  {
    id: 1,
    name: 'Non-Adhoc',
    display: 'Non-Adhoc',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'Adhoc',
    display: 'Adhoc',
    default: false,
    order: 2,
    params: {},
  },
];

const ExportPagePayrollNumberIncludedFilters: FilterOption[] = [
  {
    id: 1,
    name: 'Yes',
    display: 'Yes',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'No',
    display: 'No',
    default: false,
    order: 2,
    params: {},
  },
];

const LinkConvertedFilters: FilterOption[] = [
  {
    id: 1,
    name: 'all',
    display: 'Converted: All',
    default: true,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'converted',
    display: 'Converted: Yes',
    default: false,
    order: 2,
    params: {
      ln_job_listing__isnull: 'false',
    },
  },
  {
    id: 3,
    name: 'no',
    display: 'Converted: No',
    default: false,
    order: 3,
    params: {
      ln_job_listing__isnull: 'true',
    },
  },
];

const LinkStatusFilters: FilterOption[] = [
  {
    id: 1,
    name: 'all',
    display: 'All',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'past',
    display: 'Past',
    default: false,
    order: 2,
    params: {
      start_date_time__lt: getTodayDateISO(),
    },
  },
  {
    id: 3,
    name: 'upcoming',
    display: 'Upcoming',
    default: true,
    order: 3,
    params: {
      start_date_time__gt: getTodayDateISO(),
    },
  },
];

const ExportPagePayerAssignedFilters: FilterOption[] = [
  {
    id: 1,
    name: 'Yes',
    display: 'Yes',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'No',
    display: 'No',
    default: false,
    order: 2,
    params: {},
  },
];

const RatesLockedFilters: FilterOption[] = [
  {
    id: 1,
    name: 'all',
    display: 'Rates Locked: All',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'true',
    display: 'Rates Locked: Yes',
    default: false,
    order: 2,
    params: {
      rates_locked: 'true',
    },
  },
  {
    id: 3,
    name: 'false',
    display: 'Rates Locked: No',
    default: false,
    order: 3,
    params: {
      rates_locked: 'false',
    },
  },
];

const NonResidentOnCallFilters: FilterOption[] = [
  {
    id: 1,
    name: 'all',
    display: 'Shift Type: All',
    default: false,
    order: 1,
    params: {},
  },
  {
    id: 2,
    name: 'resident',
    display: 'Shift Type: Resident',
    default: false,
    order: 2,
    params: {
      non_resident_on_call: 'false',
    },
  },
  {
    id: 3,
    name: 'non-resident',
    display: 'Shift Type:  Non-resident',
    default: false,
    order: 3,
    params: {
      non_resident_on_call: 'true',
    },
  },
];

const directEngagementFilters: FilterOption[] = [
  {
    id: 1,
    name: 'directEngagementFalse',
    display: 'Bid Type: Non DE',
    default: false,
    order: 1,
    params: {
      direct_engagement: 'false',
    },
  },
  {
    id: 2,
    name: 'directEngagementTrue',
    display: 'Bid Type: DE',
    default: false,
    order: 2,
    params: {
      direct_engagement: 'true',
    },
  },
  {
    id: 3,
    name: 'directEngagementAny',
    display: 'Bid Type: Any',
    default: false,
    order: 3,
    params: {
      filter_candidate_bids: 'true',
    },
  },
];

export function getCascadeFiltersOptions(): ISelectOption[] {
  return CascadeFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getExportPageCascadeFiltersOptions(): ISelectOption[] {
  return ExportPageCascadeFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getPublishedFiltersOptions(): ISelectOption[] {
  return PublishedFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getEscalatedFiltersOptions(): ISelectOption[] {
  return EscalatedFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getExportPagePublishedFiltersOptions(): ISelectOption[] {
  return ExportPagePublishedFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getStatusFiltersOptions(): ISelectOption[] {
  return StatusFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getExportPageStatusFiltersOptions(): ISelectOption[] {
  return ExportPageStatusFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getExportPageStaffBankRegisteredFiltersOptions(): ISelectOption[] {
  return ExportPageStaffBankRegisteredFilters.map((filter) => ({
    id: filter.id,
    name: filter.display,
  }));
}

export function getExportPageTimesheetFormatFiltersOptions(): ISelectOption[] {
  return ExportPageTimesheetFormatFilters.map((filter) => ({
    id: filter.id,
    name: filter.display,
  }));
}

export function getExportPageAdhocFiltersOptions(): ISelectOption[] {
  return ExportPageAdhocFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getExportPagePayrollNumberIncludedFilters(): ISelectOption[] {
  return ExportPagePayrollNumberIncludedFilters.map((filter) => ({
    id: filter.id,
    name: filter.display,
  }));
}

export function getLinkConvertedFiltersOptions(): ISelectOption[] {
  return LinkConvertedFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getLinkStatusFiltersOptions(): ISelectOption[] {
  return LinkStatusFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getExportPagePayerAssignedFilters(): ISelectOption[] {
  return ExportPagePayerAssignedFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getRatesLockedFilters(): ISelectOption[] {
  return RatesLockedFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getNonResidentOnCallFilters(): ISelectOption[] {
  return NonResidentOnCallFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export function getDirectEngagementFilterOptions(): ISelectOption[] {
  return directEngagementFilters.map((filter) => ({ id: filter.id, name: filter.display }));
}

export enum ListFilterOption {
  Cascade = 'Cascade',
  Published = 'Published',
  Status = 'Status',
  LinkConverted = 'LinkConverted',
  LinkStatus = 'LinkStatus',
  RatesLocked = 'RatesLocked',
  Escalated = 'Escalated',
  NonResidentOnCall = 'NonResidentOnCall',
  DirectEngagement = 'DirectEngagement',
}

export function getParamsById(id: number, filterName: ListFilterOption) {
  if (id > 0) {
    switch (filterName) {
      case ListFilterOption.Published:
        return PublishedFilters.find((x) => x.id === id).params;
      case ListFilterOption.Status:
        return StatusFilters.find((x) => x.id === id).params;
      case ListFilterOption.LinkConverted:
        return LinkConvertedFilters.find((x) => x.id === id).params;
      case ListFilterOption.LinkStatus:
        return LinkStatusFilters.find((x) => x.id === id).params;
      case ListFilterOption.Cascade:
        return CascadeFilters.find((x) => x.id === id).params;
      case ListFilterOption.RatesLocked:
        return RatesLockedFilters.find((x) => x.id === id).params;
      case ListFilterOption.Escalated:
        return EscalatedFilters.find((x) => x.id === id).params;
      case ListFilterOption.NonResidentOnCall:
        return NonResidentOnCallFilters.find((x) => x.id === id).params;
      case ListFilterOption.DirectEngagement:
        return directEngagementFilters.find((x) => x.id === id).params;
    }
  } else {
    return null;
  }
}

export function getFilterIdByName(name: string, filterName: ListFilterOption) {
  if (name) {
    switch (filterName) {
      case ListFilterOption.Published:
        return PublishedFilters.find((x) => x.name === name).id;
      case ListFilterOption.Status:
        return StatusFilters.find((x) => x.name === name).id;
      case ListFilterOption.LinkConverted:
        return LinkConvertedFilters.find((x) => x.name === name).id;
      case ListFilterOption.LinkStatus:
        return LinkStatusFilters.find((x) => x.name === name).id;
      case ListFilterOption.Cascade:
        return CascadeFilters.find((x) => x.name === name).id;
      case ListFilterOption.RatesLocked:
        return RatesLockedFilters.find((x) => x.name === name).id;
      case ListFilterOption.Escalated:
        return EscalatedFilters.find((x) => x.name === name).id;
      case ListFilterOption.NonResidentOnCall:
        return NonResidentOnCallFilters.find((x) => x.name === name).id;
      case ListFilterOption.DirectEngagement:
        return directEngagementFilters.find((x) => x.name === name).id;
    }
  } else {
    return null;
  }
}
