import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { IPreferredSpecialtyCategoryEntity } from '../../interfaces/api/preferred-specialty-category-entity';
import { IPreferredSpecialtyCategoryEntityState } from './interfaces';
import { paginationAdapter } from './preferred-specialty-category.adapter';

export const PreferredSpecialtyCategoryPaginationMessages = paginationAdapter.getMessages();

export class UpsertPreferredSpecialtyCategoryPageMessage extends PreferredSpecialtyCategoryPaginationMessages.UpsertPageMessage {}
export class UpsertPreferredSpecialtyCategoryMultiplePagesMessage extends PreferredSpecialtyCategoryPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetPreferredSpecialtyCategoryPaginationMessage extends PreferredSpecialtyCategoryPaginationMessages.ResetPaginationMessage {}

export enum PreferredSpecialtyCategorySignalTypes {
  LOAD_PREFERRED_SPECIALTY_CATEGORIES = '[PreferredSpecialtyCategory][Signal] Load Collection',
}
export enum PreferredSpecialtyCategoryMessageTypes {
  SET_COLLECTION = '[PreferredSpecialtyCategory][Message] Set Collection',
  ADD_ONE = '[PreferredSpecialtyCategory][Message] Add One',
  UPDATE_ONE = '[PreferredSpecialtyCategory][Message] Update One',
  UPSERT_ONE = '[PreferredSpecialtyCategory][Message] Upsert One',
  DELETE_ONE = '[PreferredSpecialtyCategory][Message] Delete One',
  ADD_MULTIPLE = '[PreferredSpecialtyCategory][Message] Add All',
  DELETE_MULTIPLE = '[PreferredSpecialtyCategory][Message] Delete Many',
  UPSERT_MULTIPLE = '[PreferredSpecialtyCategory][Message] Upsert Many',
  UPDATE_MULTIPLE = '[PreferredSpecialtyCategory][Message] Update Many',
  UPDATE_ENTITY_STATE = '[PreferredSpecialtyCategory][Message] Update Entity State',
}
export class SetCollectionMessage implements Action {
  readonly type = PreferredSpecialtyCategoryMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IPreferredSpecialtyCategoryEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = PreferredSpecialtyCategoryMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IPreferredSpecialtyCategoryEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = PreferredSpecialtyCategoryMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IPreferredSpecialtyCategoryEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = PreferredSpecialtyCategoryMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IPreferredSpecialtyCategoryEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = PreferredSpecialtyCategoryMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IPreferredSpecialtyCategoryEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = PreferredSpecialtyCategoryMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IPreferredSpecialtyCategoryEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = PreferredSpecialtyCategoryMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IPreferredSpecialtyCategoryEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = PreferredSpecialtyCategoryMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = PreferredSpecialtyCategoryMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export class UpdateEntityStateMessage implements Action {
  readonly type = PreferredSpecialtyCategoryMessageTypes.UPDATE_ENTITY_STATE;

  constructor(
    public payload: { preferredSpecialtyCategoryTempState: IPreferredSpecialtyCategoryEntityState }
  ) {}
}

export type PreferredSpecialtyCategoryMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpdateEntityStateMessage;
