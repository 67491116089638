import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import { IApplicationStatusEntity } from '../../interfaces/api/application-status-entity';

export enum ApplicationStatusSignalTypes {
  LOAD_APPLICATION_STATUSES = '[ApplicationStatus][Signal] Load Collection',
}
export enum ApplicationStatusMessageTypes {
  SET_COLLECTION = '[ApplicationStatus][Message] Set Collection',
  ADD_ONE = '[ApplicationStatus][Message] Add One',
  UPDATE_ONE = '[ApplicationStatus][Message] Update One',
  UPSERT_ONE = '[ApplicationStatus][Message] Upsert One',
  DELETE_ONE = '[ApplicationStatus][Message] Delete One',
  ADD_MULTIPLE = '[ApplicationStatus][Message] Add All',
  DELETE_MULTIPLE = '[ApplicationStatus][Message] Delete Many',
  UPSERT_MULTIPLE = '[ApplicationStatus][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ApplicationStatus][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ApplicationStatusMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IApplicationStatusEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ApplicationStatusMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IApplicationStatusEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ApplicationStatusMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IApplicationStatusEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ApplicationStatusMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IApplicationStatusEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ApplicationStatusMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IApplicationStatusEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ApplicationStatusMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IApplicationStatusEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ApplicationStatusMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IApplicationStatusEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ApplicationStatusMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ApplicationStatusMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type ApplicationStatusMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
