import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IRosterEntity } from '../../interfaces/api/roster-entity';
import { paginationAdapter } from './roster.adapter';
export const { UpsertPageMessage, UpsertMultiplePagesMessage, ResetPaginationMessage } =
  paginationAdapter.getMessages();

export const RosterPaginationMessages = paginationAdapter.getMessages();

export class UpsertRosterPageMessage extends UpsertPageMessage {}
export class UpsertRosterMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetRosterPaginationMessage extends ResetPaginationMessage {}

export enum RosterSignalTypes {
  LOAD_ROSTERS = '[Roster][Signal] Load Collection',
}
export enum RosterMessageTypes {
  SET_COLLECTION = '[Roster][Message] Set Collection',
  ADD_ONE = '[Roster][Message] Add One',
  UPDATE_ONE = '[Roster][Message] Update One',
  UPSERT_ONE = '[Roster][Message] Upsert One',
  DELETE_ONE = '[Roster][Message] Delete One',
  ADD_MULTIPLE = '[Roster][Message] Add All',
  DELETE_MULTIPLE = '[Roster][Message] Delete Many',
  UPSERT_MULTIPLE = '[Roster][Message] Upsert Many',
  UPDATE_MULTIPLE = '[Roster][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = RosterMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IRosterEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = RosterMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IRosterEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = RosterMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IRosterEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = RosterMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IRosterEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = RosterMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IRosterEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = RosterMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IRosterEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = RosterMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IRosterEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = RosterMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = RosterMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type RosterMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
