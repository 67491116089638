import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { difference, flatMap, isNil } from 'lodash-es';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import { FilterStateService } from '@locumsnest/core/src';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';

import { PRODUCT_CODES } from '../../core/constants';
import {
  IPreferredSpecialtyCategoryEntity,
  IPreferredSpecialtyCategoryPostEntity,
} from '../../interfaces/api/preferred-specialty-category-entity';
import { PreferredProfessionSpecialtyService } from '../../preferred-profession-specialty/+state/preferred-profession-specialty.service';
import { ProfessionSpecialtyService } from '../../profession-specialty/+state/profession-specialty.service';
import { SpecialtyService } from '../../specialty/+state/specialty.service';
import { loadingAdapter } from './preferred-specialty-category.adapter';
import {
  PreferredSpecialtyCategoryPaginationMessages,
  ResetPreferredSpecialtyCategoryPaginationMessage,
  UpsertMultipleMessage,
  UpsertPreferredSpecialtyCategoryPageMessage,
} from './preferred-specialty-category.messages';
import { PreferredSpecialtyCategoryPersistenceService } from './preferred-specialty-category.persistence.service';
import {
  loadingStateSelectors,
  preferredSpecialtyCategoryPaginationSelectors,
  selectAllPreferredSpecialtyCategoriesTemp,
  selectAllPreferredSpecialtyCategoryIds,
  selectBySpecialtyCategoryId,
  selectBySpecialtyCategoryIdTemp,
  selectPreferredSpecialtyCategory,
  selectPreferredSpecialtyCategoryEntityState,
} from './preferred-specialty-category.selectors';
import * as tempMessages from './temp/preferred-specialty-category-temp.messages';

@Injectable({
  providedIn: 'root',
})
export class PreferredSpecialtyCategoryService extends FilterStateService<
  IPreferredSpecialtyCategoryEntity,
  UpsertPreferredSpecialtyCategoryPageMessage,
  ResetPreferredSpecialtyCategoryPaginationMessage,
  UpsertMultipleMessage
> {
  protected readonly scope = [
    PRODUCT_CODES.MATCH,
    PRODUCT_CODES.LINK,
    PRODUCT_CODES.INTELLIGENCE,
    PRODUCT_CODES.COMMUNITY,
  ];

  constructor(
    protected store: Store,
    protected persistenceService: PreferredSpecialtyCategoryPersistenceService,
    private preferredProfessionSpecialtyService: PreferredProfessionSpecialtyService,
    private professionSpecialtyService: ProfessionSpecialtyService,
    private specialtyService: SpecialtyService,
    protected microAppService: MicroAppService
  ) {
    super();
  }

  get paginationMessages() {
    return PreferredSpecialtyCategoryPaginationMessages;
  }

  get paginationSelectors() {
    return preferredSpecialtyCategoryPaginationSelectors;
  }

  get entityStateSelector() {
    return selectPreferredSpecialtyCategoryEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }

  getOne(id: number) {
    return this.store.pipe(select(selectPreferredSpecialtyCategory(id)));
  }

  getAllTemp(namespace: string = null) {
    const allEntities = isNil(namespace);

    namespace = allEntities ? 'default' : namespace;

    return this.isFilterEnabled$.pipe(
      switchMap((isFilterEnabled) => {
        if (isFilterEnabled) {
          return this.store.pipe(select(selectAllPreferredSpecialtyCategoriesTemp));
        }

        return of<IPreferredSpecialtyCategoryEntity[]>([]);
      })
    );
  }

  getOneBySpecialtyCategory(id: number, professionId: number) {
    return this.store.pipe(select(selectBySpecialtyCategoryId(id, professionId)));
  }

  getOneBySpecialtyCategoryTemp(id: number, professionId: number) {
    return this.store.pipe(select(selectBySpecialtyCategoryIdTemp(id, professionId)));
  }

  getAllAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      mergeMap(() => this.getAll())
    );
  }

  getAllSpecialtyCategoryIds() {
    return this.store.pipe(select(selectAllPreferredSpecialtyCategoryIds));
  }

  getAllSpecialtyCategoryIdsAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      mergeMap(() => this.getAllSpecialtyCategoryIds())
    );
  }

  getSpecialtyCategoriesNames() {
    return this.specialtyService.getSpecialtyNamesByIds(
      this.getAllSpecialtyCategoryIdsAfterLoading()
    );
  }
  /**
   * Adds preferred specialty category if not all options are selected
   * You can call this after adding a specialty to denote that a
   * whole category is selected id applicable(ie all specialties of that category are selected)
   */
  addSpecialtyCategoryIfAllSubSpecialtiesSelected(
    specialtyCategoryId: number,
    professionId: number,
    persist = true
  ) {
    return this.preferredProfessionSpecialtyService
      .hasTempCategory(specialtyCategoryId, professionId)
      .pipe(
        first(),
        mergeMap((hasTempCategory) => {
          if (hasTempCategory) {
            return this.getOneBySpecialtyCategoryTemp(specialtyCategoryId, professionId).pipe(
              first(),
              mergeMap((preferredSpecialtyCategory) => {
                if (isNil(preferredSpecialtyCategory)) {
                  if (persist === true) {
                    return this.persistenceService
                      .create<
                        IPreferredSpecialtyCategoryPostEntity,
                        IPreferredSpecialtyCategoryEntity
                      >({
                        specialtyCategory: specialtyCategoryId,
                        profession: professionId,
                      })
                      .pipe(
                        map(
                          (entity: IPreferredSpecialtyCategoryEntity) =>
                            new tempMessages.AddOneMessage({ entity })
                        )
                      );
                  }
                  return of<Action>();
                }
                return of<Action>();
              })
            );
          }
          return of<Action>();
        })
      );
  }
  /**
   * Deletes preferred specialty category if not all options are selected
   * You can call this before deleting one of its specialties to denote that
   * not all category is selected
   */
  deleteSpecialtyCategory(
    specialtyCategoryId: number,
    professionId: number,
    persist = true
  ): Observable<Action> {
    return this.preferredProfessionSpecialtyService
      .hasTempCategory(specialtyCategoryId, professionId)
      .pipe(
        first(),
        mergeMap((hasTempCategory) => {
          if (hasTempCategory) {
            return this.getOneBySpecialtyCategoryTemp(specialtyCategoryId, professionId).pipe(
              first(),
              mergeMap((preferredSpecialtyCategory) => {
                if (!isNil(preferredSpecialtyCategory)) {
                  if (persist === true) {
                    return this.persistenceService.delete(preferredSpecialtyCategory.id).pipe(
                      map(
                        () =>
                          new tempMessages.DeleteOneMessage({
                            id: preferredSpecialtyCategory.id,
                          })
                      )
                    );
                  }
                  return of(
                    new tempMessages.DeleteOneMessage({
                      id: preferredSpecialtyCategory.id,
                    })
                  );
                }
                return of<Action>();
              })
            );
          } else {
            return of<Action>();
          }
        })
      );
  }

  getPreferredSpecialtyCategoryIdsByProfession(professionId: number) {
    return this.getAllTemp().pipe(
      map((preferredSpecialtyCategories) =>
        preferredSpecialtyCategories
          .filter(({ profession }) => profession === professionId)
          .map((specialtyCategory) => specialtyCategory.specialtyCategory)
      )
    );
  }

  getAllIdsByProfession(professionId: number) {
    return this.getAllTemp().pipe(
      map((preferredSpecialtyCategories) =>
        preferredSpecialtyCategories
          .filter(({ profession }) => profession === professionId)
          .map((specialtyCategory) => specialtyCategory.id)
      )
    );
  }
  getPreferredSpecialtyCategoriesByProfessionList(professionList: number[]) {
    return this.getAllTemp().pipe(
      map((preferredSpecialtyCategories) =>
        preferredSpecialtyCategories.filter(({ profession }) => professionList.includes(profession))
      )
    );
  }

  getInexistentByProfession(professionId: number) {
    return combineLatest([
      this.professionSpecialtyService.getActiveCategoriesByProfessionId(professionId),
      this.getPreferredSpecialtyCategoryIdsByProfession(professionId),
    ]).pipe(map(([available, preferred]) => difference(available, preferred)));
  }

  getInexistentByProfessionList(professionIdList: number[]) {
    return combineLatest(
      professionIdList.map((profession) =>
        this.getInexistentByProfession(profession).pipe(
          map((ids) => ids.map((specialtyCategory) => ({ profession, specialtyCategory })))
        )
      )
    ).pipe(map((list) => flatMap(list)));
  }

  fetch() {
    return merge(
      this.loadAllPages('default', null),
      this.isLoaded('default').pipe(
        filter((isLoaded) => isLoaded === true),
        first(),
        mergeMap(() =>
          this.getAll().pipe(map((entities) => new tempMessages.SetCollectionMessage({ entities })))
        )
      )
    );
  }

  bulkCreate(specialtyCategories: IPreferredSpecialtyCategoryPostEntity[]) {
    return this.persistenceService.bulkCreateFilter(specialtyCategories).pipe(
      map(
        (response: IPreferredSpecialtyCategoryEntity[]) =>
          new tempMessages.UpsertMultipleMessage({
            entities: response,
          })
      )
    );
  }

  bulkDelete(preferredCategoryIds: number[]) {
    return this.persistenceService.clear({ id: preferredCategoryIds }).pipe(
      map(
        () =>
          new tempMessages.DeleteMultipleMessage({
            ids: preferredCategoryIds,
          })
      )
    );
  }

  clear() {
    return this.persistenceService
      .clear({})
      .pipe(map(() => new tempMessages.ResetCollectionMessage({})));
  }
}
