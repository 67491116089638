export enum ExternalStaffingCandidateBidStatusCodes {
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  WITHDRAWN = 'WITHDRAWN',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  APPROVAL_PENDING_AUTHORIZATION = 'APPROVAL_PENDING_AUTHORIZATION',
  DECLINE_PENDING_AUTHORIZATION = 'DECLINE_PENDING_AUTHORIZATION',
  CANCELLATION_PENDING_AUTHORIZATION = 'CANCELLATION_PENDING_AUTHORIZATION',
  DECLINE_OUT_OF_SYNC = 'DECLINE_OUT_OF_SYNC',
  CANCELLATION_OUT_OF_SYNC = 'CANCELLATION_OUT_OF_SYNC',
}

export const isNegativeBidResponseCode = (code) =>
  code === ExternalStaffingCandidateBidStatusCodes.CANCELED ||
  code === ExternalStaffingCandidateBidStatusCodes.REJECTED ||
  code === ExternalStaffingCandidateBidStatusCodes.CANCELLATION_PENDING_AUTHORIZATION ||
  code === ExternalStaffingCandidateBidStatusCodes.DECLINE_PENDING_AUTHORIZATION ||
  code === ExternalStaffingCandidateBidStatusCodes.CANCELLATION_OUT_OF_SYNC ||
  code === ExternalStaffingCandidateBidStatusCodes.DECLINE_OUT_OF_SYNC;
