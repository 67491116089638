import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IApplicationStatusEntity } from '../../interfaces/api/application-status-entity';

export function sortByName(a: IApplicationStatusEntity, b: IApplicationStatusEntity): number {
  if (a > b) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

export const adapter: EntityAdapter<IApplicationStatusEntity> =
  createEntityAdapter<IApplicationStatusEntity>({
    sortComparer: sortByName,
    selectId: (applicationStatus: IApplicationStatusEntity) => applicationStatus.val,
  });

export const initialState = adapter.getInitialState({});
