import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IGradeEntity } from '../../interfaces/api/grade-entity';

export function sortByName(a: IGradeEntity, b: IGradeEntity): number {
  return a.order - b.order;
}

export const adapter: EntityAdapter<IGradeEntity> = createEntityAdapter<IGradeEntity>({
  sortComparer: sortByName,
});

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedGradeId: null,
});
